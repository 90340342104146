import React from 'react';

const Iconbox = () => {
    return(
        <section className="gen-section-padding-3">
        <div className="container container-2">
            <div className="row">
                <div className="col-xl-4 col-md-6">
                    <div className="gen-icon-box-style-1">
                        <div className="gen-icon-box-icon">
                            <span className="gen-icon-animation">
                                <i className="fas fa-map-marker-alt"></i></span>
                        </div>
                        <div className="gen-icon-box-content">
                            <h3 className="pt-icon-box-title mb-2">
                                <span>Our Location</span>
                            </h3>
                            <p className="gen-icon-box-description">The Queen's Walk, Bishop's, London SE1 7PB, United
                                Kingdom</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 mt-4 mt-md-0">
                    <div className="gen-icon-box-style-1">
                        <div className="gen-icon-box-icon">
                            <span className="gen-icon-animation">
                                <i className="fas fa-phone-alt"></i></span>
                        </div>
                        <div className="gen-icon-box-content">
                            <h3 className="pt-icon-box-title mb-2">
                                <span>call us at</span>
                            </h3>
                            <p className="gen-icon-box-description">+ (567) 1234-567-8900<br/>+ (567) 1234-567-8901</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-12 mt-4 mt-xl-0">
                    <div className="gen-icon-box-style-1">
                        <div className="gen-icon-box-icon">
                            <span className="gen-icon-animation">
                                <i className="far fa-envelope"></i></span>
                        </div>
                        <div className="gen-icon-box-content">
                            <h3 className="pt-icon-box-title mb-2">
                                <span>Mail us</span>
                            </h3>
                            <p className="gen-icon-box-description">info@gentechtree.com<br/>info2@gentechtree.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    );
}
export default Iconbox;