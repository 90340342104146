import React from 'react';
import Loader from '../../Loader';
import Header from '../../Header';
import Singletvshow from './Singletvshow';

import Footer from '../../Footer';
import Scrolltop from '../../Scrolltop';
const Home = () => {
    return(
        <>
       <Loader />
       <Header/>
       <Singletvshow />
      
       <Footer />
        <Scrolltop/>
       </>
    );
}
export default Home;