import React from 'react';

const Blogcard = (prop) => {
    return(
        <div className="col-lg-12">
                                <div className="gen-blog-post">
                                    <div className="gen-post-media">
                                        <img src={prop.img} alt="blog-source" loading="lazy"/>
                                    </div>
                                    <div className="gen-blog-contain">
                                        <div className="gen-post-meta">
                                            <ul>
                                                <li className="gen-post-author"><i className="fa fa-user"></i>{prop.post}</li>
                                                <li className="gen-post-meta"><a href="/"><i
                                                            className="fa fa-calendar"></i>{prop.date}</a>
                                                </li>
                                                <li className="gen-post-tag">
                                                    <a href="/"><i className="fa fa-tag"></i>{prop.cat}</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <h5 className="gen-blog-title"><a href="/">{prop.title}</a></h5>
                                        <p>{prop.des}</p>
                                        <div className="gen-btn-container">
                                            <a href="/" className="gen-button">
                                                <div className="gen-button-block">
                                                    <span className="gen-button-text">Read More</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
    );
}
export default Blogcard;