import React from 'react';
import Slidercard from './Slidercard';
import Bigslidercard from './Bigslidercard';
const Scratch = () => {
    return (
        <section className="gen-section-padding-2 pt-0 pb-0">
            <div className="container-fluid px-0">
                <div className="gen-nav-movies gen-banner-movies">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="slider slider-for">
                                {/* <!-- Slider Items --> */}
                                <Bigslidercard
                                    img="images/background/asset-16.jpeg"
                                    title="Family Love" />
                                <Bigslidercard
                                    img="images/background/asset-13.jpeg"
                                    title="Friend Of Jin" />
                                <Bigslidercard
                                    img="images/background/asset-77.jpg"
                                    title="Play For Life" />
                                <Bigslidercard
                                    img="images/background/asset-37.jpeg"
                                    title="Against Beast" />



                                {/* <!-- Slider Items --> */}
                            </div>
                            <div className="slider slider-nav">

                                <Slidercard
                                    img="images/background/asset-16.jpeg"
                                    title="Family Love"
                                />
                                <Slidercard
                                    img="images/background/asset-13.jpeg"
                                    title="Friend Of Jin"
                                />
                                <Slidercard
                                    img="images/background/asset-77.jpg"
                                    title="Play For Life"
                                />
                                <Slidercard
                                    img="images/background/asset-37.jpeg"
                                    title="Against Beast"
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Scratch;