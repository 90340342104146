import React from 'react';

const Priceblock = () => {
    return(
        <div className="gen-section-padding-3">
        <div className="container container-2">
            <div className="row">
                <div className="col-lg-12">
                    <div className="gen-comparison-table table-style-1 table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="cell-inner">
                                        </div>
                                        <div className="cell-tag">
                                            <span></span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="cell-inner">
                                            <span>Basic</span>
                                        </div>
                                        <div className="cell-tag">
                                            <span></span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="cell-inner">
                                            <span>Standard</span>
                                        </div>
                                        <div className="cell-tag">
                                            <span></span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="cell-inner">
                                            <span>Premium</span>
                                        </div>
                                        <div className="cell-tag">
                                            <span></span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>49$</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>99$</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>199$</span><span> / Month</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                            <span>Number Of Screen</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>1</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>2</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>4</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                            <span>On how many device you can Download</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>1</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>2</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <span>4</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                            <span>Unlimited TV shows and movies </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                            <span>watch on mobile and tablet</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                            <span>watch on laptop and tv</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                            <span>HD available</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="fas fa-times-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                            <span>ultra HD available</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="fas fa-times-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cell-inner">
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                        </div>
                                        <div className="cell-btn-holder">
                                            <div className="gen-btn-container">
                                                <div className="gen-button-block">
                                                    <a className="gen-button" href="/">
                                                        <span className="text">Subscribe</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                        </div>
                                        <div className="cell-btn-holder">
                                            <div className="gen-btn-container">
                                                <div className="gen-button-block">
                                                    <a className="gen-button" href="/">
                                                        <span className="text">Subscribe</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-inner">
                                        </div>
                                        <div className="cell-btn-holder">
                                            <div className="gen-btn-container">
                                                <div className="gen-button-block">
                                                    <a className="gen-button" href="/">
                                                        <span className="text">Subscribe</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    );
}
export default Priceblock;