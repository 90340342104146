import React from 'react';

const Episod = (prop) => {
    return(
        <div className="item">
        <div className="gen-episode-contain">
            <div className="gen-episode-img">
                <img src="images/background/asset-15.jpeg" alt="stream-lab-source"/>
                <div className="gen-movie-action">
                    <a href="/" className="gen-button">
                        <i className="fa fa-play"></i>
                    </a>
                </div>
            </div>
            <div className="gen-info-contain">
                <div className="gen-episode-info">
                    <h3>
                       {prop.Epi}<span>-</span>
                        <a href="/">
                            Looking her
                        </a>
                    </h3>
                </div>
                <div className="gen-single-meta-holder">
                    <ul>
                        <li className="run-time">40min</li>
                        <li className="release-date">
                            Sep 10 2018
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    );
}
export default Episod;