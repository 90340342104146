import React from 'react';

const Footer = () => {
    return(
        <footer id="gen-footer">
      <div className="gen-footer-style-1">
         <div className="gen-footer-top">
            <div className="container">
               <div className="row">
                  <div className="col-xl-3 col-md-6">
                     <div className="widget">
                        <div className="row">
                           <div className="col-sm-12">
                              <img src="images/asset-0.png" className="gen-footer-logo" alt="gen-footer-logo"/>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                              <ul className="social-link">
                                 <li><a href="/" className="facebook"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="/" className="facebook"><i className="fab fa-instagram"></i></a></li>
                                 <li><a href="/" className="facebook"><i className="fab fa-skype"></i></a></li>
                                 <li><a href="/" className="facebook"><i className="fab fa-twitter"></i></a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                     <div className="widget">
                        <h4 className="footer-title">Explore</h4>
                        <div className="menu-explore-container">
                           <ul className="menu">
                              <li className="menu-item">
                                 <a href="/" aria-current="page">Home</a>
                              </li>
                              <li className="menu-item"><a href="/moviepagination">Movies</a></li>
                              <li className="menu-item"><a href="/tvshowpagination">Tv Shows</a></li>
                              <li className="menu-item"><a href="/videopagination">Videos</a></li>
                              <li className="menu-item"><a href="/">Actors</a></li>
                              <li className="menu-item"><a href="/">Basketball</a></li>
                              <li className="menu-item"><a href="/">Celebrity</a></li>
                              <li className="menu-item"><a href="/">Cross</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                     <div className="widget">
                        <h4 className="footer-title">Company</h4>
                        <div className="menu-about-container">
                           <ul className="menu">
                              <li className="menu-item"><a href="/contactus">Company</a>
                              </li>
                              <li className="menu-item"><a href="/contactus">Privacy
                                    Policy</a></li>
                              <li className="menu-item"><a href="/contactus">Terms Of
                                    Use</a></li>
                              <li className="menu-item"><a href="/contactus">Help
                                    Center</a></li>
                              <li className="menu-item"><a href="/contactus">contact us</a></li>
                              <li className="menu-item"><a href="/pricestyleone">Subscribe</a></li>
                              <li className="menu-item"><a href="/">Our Team</a></li>
                              <li className="menu-item"><a href="/contactus">Faq</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3  col-md-6">
                     <div className="widget">
                        <h4 className="footer-title">Downlaod App</h4>
                        <div className="row">
                           <div className="col-sm-12">
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                              <a href="/">
                                 <img src="images/asset-35.png" className="gen-playstore-logo" alt="playstore"/>
                              </a>
                              <a href="/">
                                 <img src="images/asset-36.png" className="gen-appstore-logo" alt="appstore"/>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="gen-copyright-footer">
            <div className="container">
               <div className="row">
                  <div className="col-md-12 align-self-center">
                     <span className="gen-copyright"><a target="_blank" href="/"> Copyright 2021 stremlab All Rights
                           Reserved.</a></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
    );
}
export default Footer;