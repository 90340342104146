import React from 'react';

const Scratch = (prop) => {
   return (
      <div className="item" style={{ background: `url(${prop.img})` }}>
                              <div className="gen-movie-contain h-100">
                                  <div className="container h-100">
                                      <div className="row align-items-center h-100">
                                          <div className="col-xl-6">
                                              <div className="gen-tag-line"><span>{prop.relcat}</span></div>
                                              <div className="gen-movie-info">
                                                  <h3>{prop.title}</h3>
                                              </div>
                                              <div className="gen-movie-meta-holder">
                                                  <ul>
                                                      <li className="gen-sen-rating">
                                                          <span>
                                                              {prop.cat}</span>
                                                      </li>
                                                      <li>{prop.time}</li>
                                                      <li> <img src="images/asset-2.png" alt="streamlab-source"/>
                                                          <span>
                                                              {prop.ver}</span>
                                                      </li>
                                                      <li>
                                                          {prop.rel}
                                                      </li>
                                                      <li>
                                                          <a href="/action"><span>{prop.fell}</span></a>
                                                      </li>
                                                  </ul>
                                                  <p>Streamlab is a long established fact that a reader will be
                                                      distracted by the readable content of a page when Streamlab at
                                                      its layout Streamlab.</p>
                                              </div>
                                              <div className="gen-movie-action">
                                                  <div className="gen-btn-container button-1">
                                                      <a href="/singlemovie" className="gen-button">
                                                          <i aria-hidden="true" className="ion ion-play"></i> <span
                                                              className="text">Play now</span>
                                                      </a>
                                                  </div>
                                                  <div className="gen-btn-container button-2">
                                                      <a href="/singlemovie"
                                                          className="gen-button popup-youtube popup-vimeo popup-gmaps gen-button-link">
                                                          <span className="text">Watch Trailer</span>
                                                      </a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
   );
}
export default Scratch;