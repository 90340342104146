import React from 'react';

const Loader = () => {
    return(
        <div id="gen-loading">
        <div id="gen-loading-center">
           <img src="images/logo-1.png" alt="loading"/>
        </div>
     </div>
    );
}
export default Loader;