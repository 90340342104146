import React from 'react';
import Moviecard from './Moviecard';

const Scratch = () => {
    return (
        <>
            <section className="gen-section-padding-3 gen-single-video">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="gen-video-holder">
                                        <iframe title="frem" width="100%" height="550px" src="https://www.youtube.com/embed/LXb3EKWsInQ"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullscreen></iframe>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="single-video">
                                        <div className="gen-single-video-info">
                                            <h2 className="gen-title">robot war</h2>
                                            <div className="gen-single-meta-holder">
                                                <ul>
                                                    <li>2 years</li>
                                                    <li>
                                                        <a href="/"><span>Tennis</span></a>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-eye">
                                                        </i>
                                                        <span>225 Views</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>Streamlab is a long established fact that a reader will be distracted by the
                                            readable
                                            content of a page when Streamlab at its layout. The point of using Lorem
                                            Streamlab
                                            is that it has a more-or-less normal distribution of Streamlab as opposed
                                            Streamlab.
                                    </p>
                                            <div className="gen-socail-share mt-0">
                                                <h4 className="align-self-center">Social Share :</h4>
                                                <ul className="social-inner">
                                                    <li><a href="/" className="facebook"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="/" className="facebook"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a href="/" className="facebook"><i className="fab fa-twitter"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="pm-inner">
                                            <div className="gen-more-like">
                                                <h5 className="gen-more-title">More Like This</h5>
                                                <div className="row">

                                                    <Moviecard
                                                        img="images/background/asset-41.jpg"
                                                        title="sefozie world"
                                                        week="3 weeks"
                                                        type="Adventure"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-36.jpg"
                                                        title="war hands"
                                                        week="3 weeks"
                                                        type="Animation"

                                                    />

                                                    <Moviecard
                                                        img="images/background/asset-38.jpg"
                                                        title="MGX-fighter"
                                                        week="3 weeks"
                                                        type="Adventure"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-36.jpg"
                                                        title="voho the skull land"
                                                        week="3 weeks"
                                                        type="Horror"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-37.jpeg"
                                                        title="Bigfoot Silva"
                                                        week="2 years"
                                                        type="Action"

                                                    />

                                                    <Moviecard
                                                        img="images/background/asset-35.jpg"
                                                        title="Robot War"
                                                        week="2 years"
                                                        type="Tennis"

                                                    />

                                                    <Moviecard
                                                        img="images/background/asset-2.jpg"
                                                        title="nature world"
                                                        week="2 years"
                                                        type="Traveling"

                                                    />

                                                    <Moviecard
                                                        img="images/background/asset-39.jpg"
                                                        title="AI:world"
                                                        week="2 years"
                                                        type="cricket"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-5.jpeg"
                                                        title="Horse Ride"
                                                        week="2 years"
                                                        type="cricket"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-11.jpeg"
                                                        title="Big Machine"
                                                        week="2 years"
                                                        type="Traveling"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-41.jpg"
                                                        title="sefozie world"
                                                        week="3 weeks"
                                                        type="Adventure"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-36.jpg"
                                                        title="war hands"
                                                        week="3 weeks"
                                                        type="Animation"

                                                    />

                                                    <Moviecard
                                                        img="images/background/asset-38.jpg"
                                                        title="MGX-fighter"
                                                        week="3 weeks"
                                                        type="Adventure"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-36.jpg"
                                                        title="voho the skull land"
                                                        week="3 weeks"
                                                        type="Horror"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-37.jpeg"
                                                        title="Bigfoot Silva"
                                                        week="2 years"
                                                        type="Action"

                                                    />

                                                    <Moviecard
                                                        img="images/background/asset-35.jpg"
                                                        title="Robot War"
                                                        week="2 years"
                                                        type="Tennis"

                                                    />

                                                    <Moviecard
                                                        img="images/background/asset-2.jpg"
                                                        title="nature world"
                                                        week="2 years"
                                                        type="Traveling"

                                                    />

                                                    <Moviecard
                                                        img="images/background/asset-39.jpg"
                                                        title="AI:world"
                                                        week="2 years"
                                                        type="cricket"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-5.jpeg"
                                                        title="Horse Ride"
                                                        week="2 years"
                                                        type="cricket"

                                                    />
                                                    <Moviecard
                                                        img="images/background/asset-11.jpeg"
                                                        title="Big Machine"
                                                        week="2 years"
                                                        type="Traveling"

                                                    />                                   </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="gen-load-more-button">
                                                        <div className="gen-btn-container">
                                                            <a className="gen-button gen-button-loadmore" href="/">
                                                                <span className="button-text">Load More</span>
                                                                <span className="loadmore-icon" style={{ display: "none" }}><i
                                                                    className="fa fa-spinner fa-spin"></i></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}
export default Scratch;