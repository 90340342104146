import React from 'react';

const Scratch = (prop) => {
    return(
        <div className="item" style={{ background: `url(${prop.img})` }}>
        <div className="gen-movie-contain h-100">
           <div className="container h-100">
              <div className="row align-items-center h-100">
                 <div className="col-xl-6">
                    <div className="gen-movie-info">
                       <h3>{prop.title}</h3>
                    </div>
                    <div className="gen-movie-meta-holder">
                       <ul>
                          <li>{prop.season}</li>
                          <li>{prop.ep}</li>
                          <li>{prop.dur}</li>
                          <li>
                             <a href="/"><span>{prop.cat}</span></a>
                          </li>
                       </ul>
                       <p>Streamlab is a long established fact that a reader will be distracted by the
                          readable content of a page when Streamlab at its layout Streamlab.</p>
                    </div>
                    <div className="gen-movie-action">
                       <div className="gen-btn-container">
                          <a href="/singleepisod" className="gen-button gen-button-dark">
                             <span className="text">Play now</span>
                          </a>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
    );
}
export default Scratch;