import React from 'react';

const Scratch = (prop) => {
    return(
        <div className="item" style={{ background: `url(${prop.img})` }}>
        <div className="gen-movie-contain h-100">
            <div className="container h-100">
                <div className="row align-items-center h-100">
                    <div className="col-xl-6">
                        <div className="gen-movie-info">
                            <h3>{prop.title}</h3>
                        </div>
                        <div className="gen-movie-meta-holder">
                            <ul>
                                <li>{prop.seo}</li>
                                <li>{prop.ep}</li>
                                <li>{prop.dur}</li>
                                <li>
                                    <a href="/action"><span>{prop.type}</span></a>
                                </li>
                            </ul>
                            <p>Streamlab is a long established fact that a reader will be
                                distracted by the readable content of a page when Streamlab at
                                its layout. The point of using Lorem Streamlab is that it has a
                                more-or-less normal distribution of Streamlab as opposed
                                Streamlab.</p>
                        </div>
                        <div className="gen-movie-action">
                            <div className="gen-btn-container button-1">
                                <a href="/singlemovie" className="gen-button">
                                    <i aria-hidden="true" className="ion ion-play"></i> <span
                                        className="text">Play Now</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default Scratch;