import React from 'react';

const Breadcrumb = (prop) => {
    return(
        <div className="gen-breadcrumb" style={{backgroundImage: "url('images/background/asset-25.jpeg')"}}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <nav aria-label="breadcrumb">
                        <div className="gen-breadcrumb-title">
                            <h1>
                               {prop.titles}
                            </h1>
                        </div>
                        <div className="gen-breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/"><i
                                            className="fas fa-home mr-2"></i>Home</a></li>
                                <li className="breadcrumb-item active">{prop.title}</li>
                            </ol>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    );
}
export default Breadcrumb;