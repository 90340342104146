import React from 'react';
import Moviecard from './Moviecard';
const Movies = () => {
    return (
        <section className="gen-section-padding-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">


                            <Moviecard
                                img="images/background/asset-5.jpeg"
                                title="The warrior life"
                                time="2hr 00mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-4.jpeg"
                                title="Thieve the bank"
                                time="30mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-23.jpeg"
                                title="love your life"
                                time="1hr 46mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-26.jpeg"
                                title="spaceman the voyager"
                                time="1hr 32mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-24.jpeg"
                                title="The last witness"
                                time="1hr 37mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-29.jpeg"
                                title="shimu the elephant"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-33.jpeg"
                                title="black water"
                                time="1hr 44mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-8.jpeg"
                                title="shipe of full moon"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-51.jpg"
                                title="The journey of a champion"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Moviecard
                                img="images/background/asset-25.jpeg"
                                title="fight for life"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Moviecard
                                img="images/background/asset-12.jpeg"
                                title="common man's idea"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Moviecard
                                img="images/background/asset-32.jpeg"
                                title="love, simon"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-27.jpeg"
                                title="i can only imagine"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-13.jpeg"
                                title="a jin's friend"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-46.jpg"
                                title="let's solve k'y mystery"
                                time="1hr 54mins"
                                type="Action"

                            />
                          

                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="gen-load-more-button">
                            <div className="gen-btn-container">
                                <a className="gen-button gen-button-loadmore" href="/">
                                    <span className="button-text">Load More</span>
                                    <span className="loadmore-icon" style={{ display: "none" }}><i
                                        className="fa fa-spinner fa-spin"></i></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Movies;