import React from 'react';
import Videocard from './Videocard';
const Video = () => {
    return(
        <section className="gen-section-padding-2">
        <div className="container">
           <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                 <h4 className="gen-heading-title">Watch For Free: Movie Mania</h4>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block">
                 <div className="gen-movie-action">
                    <div className="gen-btn-container text-right">
                       <a href="/tvshowpagination" className="gen-button gen-button-flat">
                          <span className="text">More Videos</span>
                       </a>
                    </div>
                 </div>
              </div>
           </div>
           <div className="row mt-3">
              <div className="col-12">
                 <div className="gen-style-2">
                    <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true" data-desk_num="4"
                       data-lap_num="3" data-tab_num="2" data-mob_num="1" data-mob_sm="1" data-autoplay="false"
                       data-loop="false" data-margin="30">
                       

                       <Videocard
                                    img="images/background/asset-1.jpeg"
                                    title="King of Skull"
                                    time="2hr 13mins"
                                    link="/action"
                                />
                                <Videocard
                                    img="images/background/asset-26.jpeg"
                                    title="Spaceman The Voyager"
                                    time="1h 32mins"
                                    link="/register"
                                />
                                <Videocard
                                    img="images/background/asset-27.jpeg"
                                    title="I Can Only Imagine"
                                    time="1hr 24 mins"
                                    link="/register"
                                />
                                <Videocard
                                    img="images/background/asset-3.jpeg"
                                    title="The Express"
                                    time="2hr 35mins"
                                    link="/action"
                                />
                                <Videocard
                                    img="images/background/asset-28.jpeg"
                                    title="Chapter & Verse"
                                    time="1hr 37mins"
                                    link="/action"
                                />
  
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
    );
}
export default Video;