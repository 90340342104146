import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './Home/Main-home';
import Movieshome from './Home/Movieshome';
import Tvshowshome from '../Components/Home/Tvshows-home';
import Videohome from './Home/Video-home';
import Movieloadmore from './Movie-pages/Loadmore';
import Movieinfinitescroll from './Movie-pages/Infinitescroll';
import Moviepagination from './Movie-pages/Pagination';
import Moviestyleone from './Movie-pages/Moviestyle-one';
import Moviestyletwo from './Movie-pages/Moviestyle-two';
import Moviestylethree from './Movie-pages/Moviestyle-three';
import Singlemovie from './Movie-pages/Singlemovie';
import Tvshowloadmore from './Tvshows/Loadmore';
import Tvshowinfinitescroll from './Tvshows/Infinitescroll';
import Tvshowpagination from './Tvshows/Pagination';
import Tvshowstyleone from './Tvshows/Tvshowstyle-one';
import Tvshowstyletwo from './Tvshows/Tvshowstyle-two';
import Tvshowstylethree from './Tvshows/Tvshowstyle-three';
import Singletvshow from './Tvshows/Singletvshow';
import Singleepisod from './Tvshows/Singleepisod';
import Videoloadmore from './Video-pages/Loadmore';
import Videoinfinitescroll from './Video-pages/Infinitescroll';
import Videopagination from './Video-pages/Pagination';
import Videostyleone from './Video-pages/Videostyle-one';
import Videostyletwo from './Video-pages/Videostyle-two';
import Videostylethree from './Video-pages/Videostyle-three';
import Singlevideo from './Video-pages/Singlevideo';
import Blogleft from './Pages/Blog-left';
import Blogright from './Pages/Blog-right';
import Pricestyle1 from './Pages/Pricing-style-one';
import Pricestyle2 from './Pages/Pricing-style-two'; 
import Contactus from './Pages/Contactus';
import Login from './Profile-page/Log-in';
import Register from './Profile-page/Register';
import Library from './Profile-page/Library';
import Movieplaylist from './Profile-page/Movieplaylist';
import Tvshowplaylist from './Profile-page/Tvshowplaylist';
import Videoplaylist from './Profile-page/Videoplaylist';
import Uploadvideo from './Profile-page/Uploadvideo';
import Action from './Extra-pages/Action';
import Adventure from './Extra-pages/Adventure';
import Biography from './Extra-pages/biograf';
import Recover from './Extra-pages/Recoverypass';
import Error404 from './Extra-pages/Error404';

const MainNavigation = () => {
    return(
        <>
          <Router>
      <Switch>
        {/** Home */}
      
        <Route  path="/" exact={true} component={Home} />
        <Route  path="/movieshome" exact={true} component={Movieshome} />
        <Route  path="/tvshowshome" exact={true} component={Tvshowshome} />
        <Route  path="/videohome" exact={true} component={Videohome} />
         {/** Movie Pages */}
        
         <Route  path="/movieloadmore" exact={true} component={Movieloadmore} />
         <Route  path="/movieInfinitescroll" exact={true} component={Movieinfinitescroll} />
         <Route  path="/moviepagination" exact={true} component={Moviepagination} />
         <Route  path="/Moviestyleone" exact={true} component={Moviestyleone} />
         <Route  path="/Moviestyletwo" exact={true} component={Moviestyletwo} />
         <Route  path="/Moviestylethree" exact={true} component={Moviestylethree} />
         <Route  path="/singlemovie" exact={true} component={Singlemovie} />
          {/** Tv Shows Pages */}
         
         <Route  path="/tvshowloadmore" exact={true} component={Tvshowloadmore} />
         <Route  path="/tvshowInfinitescroll" exact={true} component={Tvshowinfinitescroll} />
         <Route  path="/tvshowpagination" exact={true} component={Tvshowpagination} />
         <Route  path="/tvshowstyleone" exact={true} component={Tvshowstyleone} />
         <Route  path="/tvshowstyletwo" exact={true} component={Tvshowstyletwo} />
         <Route  path="/tvshowstylethree" exact={true} component={Tvshowstylethree} />
         <Route  path="/Singletvshow" exact={true} component={Singletvshow} />
         <Route  path="/Singleepisod" exact={true} component={Singleepisod} />
            {/** Video Pages */}
         
         <Route  path="/videoloadmore" exact={true} component={Videoloadmore} />
         <Route  path="/videoInfinitescroll" exact={true} component={Videoinfinitescroll} />
         <Route  path="/videopagination" exact={true} component={Videopagination} />
         <Route  path="/videostyleone" exact={true} component={Videostyleone} />
         <Route  path="/videostyletwo" exact={true} component={Videostyletwo} />
         <Route  path="/videostylethree" exact={true} component={Videostylethree} />
         <Route  path="/singlevideo" exact={true} component={Singlevideo} />   
           {/** Video Pages */}
           
           <Route  path="/blogleft" exact={true} component={Blogleft} />  
           <Route  path="/blogright" exact={true} component={Blogright} />  
           <Route  path="/pricestyleone" exact={true} component={Pricestyle1} />  
           <Route  path="/pricestyletwo" exact={true} component={Pricestyle2} />  
           <Route  path="/contactus" exact={true} component={Contactus} />  
           {/* profile pages */}
           <Route  path="/login" exact={true} component={Login} />  
           <Route  path="/register" exact={true} component={Register} />
           <Route  path="/library" exact={true} component={Library} />
           <Route  path="/movieplaylist" exact={true} component={Movieplaylist} />
           <Route  path="/tvshowplaylist" exact={true} component={Tvshowplaylist} />
           <Route  path="/videoplaylist" exact={true} component={Videoplaylist} />
           <Route  path="/uploadvideo" exact={true} component={Uploadvideo} />
          {/*  Extra pages */}.
          <Route  path="/action" exact={true} component={Action} />
          <Route  path="/adventure" exact={true} component={Adventure} />
          <Route  path="/Biography" exact={true} component={Biography} />
          <Route  path="/recover" exact={true} component={Recover} />
          <Route  path="/error404" exact={true} component={Error404} />

          <Route component={Error404} />
             
        </Switch>
        </Router>
        </>

    );
}
export default MainNavigation;