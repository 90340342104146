import React from 'react';
import Loader from '../../Loader';
import Header from '../../Header';
import Breadcrumb from '../../Breadcrumb';
import Blogright from './Blogright';

import Footer from '../../Footer';
import Scrolltop from '../../Scrolltop';
const Blogindexright = () => {
    return (
        <>
            <Loader />
            <Header />
            <Breadcrumb  titles="blog Right sidebar"
             title=" blog Right sidebar" />
             <Blogright />

            <Footer />
            <Scrolltop />
        </>
    );
}
export default Blogindexright;