import React from 'react';

const Register = () => {
    const dat = () => {
        return false;
    }

    return (
        <>
        <h1>Register file </h1>  
         <section className="position-relative pb-0">
        <div className="gen-register-page-background" style={{backgroundImage: "url('images/background/asset-3.jpeg')"}}>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center">
                        <form id="pms_register-form" onSubmit={dat} className="pms-form">
                            <h4>Register</h4>
                            <input type="hidden" id="pmstkn" name="pmstkn" /><input type="hidden"
                                name="_wp_http_referer" value="/register/"/>
                            <ul className="pms-form-fields-wrapper pl-0">
                                <li className="pms-field pms-user-login-field ">
                                    <label for="pms_user_login">Username *</label>
                                    <input id="pms_user_login" name="user_login" type="text"/>
                                </li>
                                <li className="pms-field pms-user-email-field ">
                                    <label for="pms_user_email">E-mail *</label>
                                    <input id="pms_user_email" name="user_email" type="text"/>
                                </li>
                                <li className="pms-field pms-first-name-field ">
                                    <label for="pms_first_name">First Name</label>
                                    <input id="pms_first_name" name="first_name" type="text" />
                                </li>
                                <li className="pms-field pms-last-name-field ">
                                    <label for="pms_last_name">Last Name</label>
                                    <input id="pms_last_name" name="last_name" type="text" />
                                </li>
                                <li className="pms-field pms-pass1-field">
                                    <label for="pms_pass1">Password *</label>
                                    <input id="pms_pass1" name="pass1" type="password"/>
                                </li>
                                <li className="pms-field pms-pass2-field">
                                    <label for="pms_pass2">Repeat Password *</label>
                                    <input id="pms_pass2" name="pass2" type="password"/>
                                </li>
                                <li className="pms-field pms-field-subscriptions ">
                                    <div className="pms-subscription-plan"><label><input type="radio"
                                                name="subscription_plans" data-price="199" data-duration="1"
                                                value="7330" checked="checked" data-default-selected="true"/><span
                                                className="pms-subscription-plan-name">Premium</span><span
                                                className="pms-subscription-plan-price"><span className="pms-divider"> -
                                                </span><span className="pms-subscription-plan-price-value">199</span><span
                                                    className="pms-subscription-plan-currency">$</span><span
                                                    className="pms-divider"> / </span>1 Month</span><span
                                                className="pms-subscription-plan-trial"></span><span
                                                className="pms-subscription-plan-sign-up-fee"></span></label></div>
                                    <div className="pms-subscription-plan"><label><input type="radio"
                                                name="subscription_plans" data-price="99" data-duration="1" value="7329"
                                                data-default-checked="false"/><span
                                                className="pms-subscription-plan-name">Standard</span><span
                                                className="pms-subscription-plan-price"><span className="pms-divider"> -
                                                </span><span className="pms-subscription-plan-price-value">99</span><span
                                                    className="pms-subscription-plan-currency">$</span><span
                                                    className="pms-divider"> / </span>1 Month</span><span
                                                className="pms-subscription-plan-trial"></span><span
                                                className="pms-subscription-plan-sign-up-fee"></span></label></div>
                                    <div className="pms-subscription-plan"><label><input type="radio"
                                                name="subscription_plans" data-price="29" data-duration="1" value="7328"
                                                data-default-checked="false"/><span
                                                className="pms-subscription-plan-name">Basic</span><span
                                                className="pms-subscription-plan-price"><span className="pms-divider"> -
                                                </span><span className="pms-subscription-plan-price-value">29</span><span
                                                    className="pms-subscription-plan-currency">$</span><span
                                                    className="pms-divider"> / </span>1 Month</span><span
                                                className="pms-subscription-plan-trial"></span><span
                                                className="pms-subscription-plan-sign-up-fee"></span></label></div>
                                    <div id="pms-paygates-wrapper"><input type="hidden" className="pms_pay_gate"
                                            name="pay_gate" value="paypal_standard"/></div>
                                </li>
                            </ul>
                            <span id="pms-submit-button-loading-placeholder-text" className="d-none">Processing. Please
                                wait...</span>
                            <input name="pms_register" type="submit" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    );
}
export default Register;