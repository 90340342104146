import React from 'react';

const Pricebox = () => {
    return(
        <section className="gen-section-padding-3">
        <div className="container container-2">
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4">
                    <div className="gen-price-block text-center">
                        <div className="gen-price-detail">
                            <span className="gen-price-title"> BASIC </span>
                            <h2 className="price">$ 29.00</h2>
                            <p className="gen-price-duration">/ Per Month</p>
                            <div className="gen-bg-effect">
                                <img src="images/background/asset-54.jpg" alt="stream-lab-source"/>
                            </div>
                        </div>
                        <ul className="gen-list-info">
                            <li>
                                Number Of Screen
                            </li>
                            <li>
                                On how many device you can Download
                            </li>
                            <li>
                                Unlimited TV shows and movies
                            </li>
                            <li>
                                <del>watch on mobile and tablet</del>
                            </li>
                            <li>
                                <del>watch on laptop and tv</del>
                            </li>
                            <li>
                                <del>HD available</del>
                            </li>
                            <li>
                                <del>ultra HD available</del>
                            </li>
                        </ul>
                        <div className="gen-btn-container button-1">
                            <a href="/" className="gen-button">
                                <span className="text">Purchase now</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 mt-3 mt-md-0">
                    <div className="gen-price-block text-center active">
                        <div className="gen-price-detail">
                            <span className="gen-price-title"> Standard </span>
                            <h2 className="price">$ 99.00</h2>
                            <p className="gen-price-duration">/ Per Month</p>
                            <div className="gen-bg-effect">
                                <img src="images/background/asset-54.jpg" alt="architek-source"/>
                            </div>
                        </div>
                        <ul className="gen-list-info">
                            <li>
                                Number Of Screen
                            </li>
                            <li>
                                On how many device you can Download
                            </li>
                            <li>
                                Unlimited TV shows and movies
                            </li>
                            <li>
                                <del>watch on mobile and tablet</del>
                            </li>
                            <li>
                                <del>watch on laptop and tv</del>
                            </li>
                            <li>
                                <del>HD available</del>
                            </li>
                            <li>
                                <del>ultra HD available</del>
                            </li>
                        </ul>
                        <div className="gen-btn-container button-1">
                            <a href="/" className="gen-button">
                                <span className="text">Purchase now</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 mt-3 mt-md-0">
                    <div className="gen-price-block text-center">
                        <div className="gen-price-detail">
                            <span className="gen-price-title"> Premium </span>
                            <h2 className="price">$ 199.00</h2>
                            <p className="gen-price-duration">/ Per Month</p>
                            <div className="gen-bg-effect">
                                <img src="images/background/asset-54.jpg" alt="architek-source"/>
                            </div>
                        </div>
                        <ul className="gen-list-info">
                            <li>
                                Number Of Screen
                            </li>
                            <li>
                                On how many device you can Download
                            </li>
                            <li>
                                Unlimited TV shows and movies
                            </li>
                            <li>
                                <del>watch on mobile and tablet</del>
                            </li>
                            <li>
                                <del>watch on laptop and tv</del>
                            </li>
                            <li>
                                <del>HD available</del>
                            </li>
                            <li>
                                <del>ultra HD available</del>
                            </li>
                        </ul>
                        <div className="gen-btn-container button-1">
                            <a href="/" className="gen-button">
                                <span className="text">Purchase now</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    );
}
export default Pricebox;