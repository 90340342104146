import React from 'react';
import Videocard from './Videocard';

const Video = () => {
    return (
        <section className="gen-section-padding-2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <h4 className="gen-heading-title">Powerful Crime Thrillers</h4>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block">
                        <div className="gen-movie-action">
                            <div className="gen-btn-container text-right">
                                <a href="/tvshowpagination" className="gen-button gen-button-flat">
                                    <span className="text">More Videos</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="gen-style-2">
                            <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true"
                                data-desk_num="4" data-lap_num="3" data-tab_num="2" data-mob_num="1" data-mob_sm="1"
                                data-autoplay="false" data-loop="false" data-margin="30">


                                <Videocard
                                    img="images/background/asset-10.jpeg"
                                    title="skull of myths"
                                    time="1hr 24 mins"
                                />
                                <Videocard
                                    img="images/background/asset-12.jpeg"
                                    title="common man’s idea"
                                    time="1hr 51mins"
                                />

                                <Videocard
                                    img="images/background/asset-29.jpeg"
                                    title="shimu the elephant"
                                    time="1hr 17mins"
                                />
                                 <Videocard
                                    img="images/background/asset-30.jpeg"
                                    title="War of Rejonse"
                                    time="2hr 20 mins"
                                />
                               
                                 <Videocard
                                    img="images/background/asset-31.jpeg"
                                    title="The Big Sick"
                                    time="2hr 25 mins"
                                />
                                <Videocard
                                    img="images/background/asset-24.jpeg"
                                    title="The Last Witness"
                                    time="1hr 17mins"
                                />
                                 <Videocard
                                    img="images/background/asset-32.jpeg"
                                    title="Love, Simon"
                                    time="1hr 50 mins"
                                />
                               
                                 <Videocard
                                    img="images/background/asset-33.jpeg"
                                    title="Black Water"
                                    time="2hr 25 mins"
                                />
                                 <Videocard
                                    img="images/background/asset-34.jpeg"
                                    title="Bad Genius"
                                    time="1hr 17mins"
                                />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Video;