import React from 'react';
import Videocard from './Videocard';

const Video = () => {
    return (
        <section className="gen-section-padding-2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <h4 className="gen-heading-title">Most Viewed</h4>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block">
                        <div className="gen-movie-action">
                            <div className="gen-btn-container text-right">
                                <a href="/tvshowpagination" className="gen-button gen-button-flat">
                                    <span className="text">More Videos</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="gen-style-2">
                            <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true" data-desk_num="4"
                                data-lap_num="3" data-tab_num="2" data-mob_num="1" data-mob_sm="1" data-autoplay="false"
                                data-loop="false" data-margin="30">

                                <Videocard
                                    img="images/background/asset-5.jpeg"
                                    title="The warrior life"
                                    time="2hr 00mins"
                                    link="/action"
                                />
                                <Videocard
                                    img="images/background/asset-6.jpeg"
                                    title="machine war"
                                    time="1h 22mins"
                                    link="/register"
                                />
                                <Videocard
                                    img="images/background/asset-7.jpeg"
                                    title="the horse lady"
                                    time="1hr 24 mins"
                                    link="/register"
                                />
                                <Videocard
                                    img="images/background/asset-8.jpeg"
                                    title="ship of full moon"
                                    time="1hr 35mins"
                                    link="/action"
                                />
                                <Videocard
                                    img="images/background/asset-9.jpeg"
                                    title="Rebuneka the doll"
                                    time="1hr 44 mins"
                                    link="/action"
                                />
                                <Videocard
                                    img="images/background/asset-4.jpeg"
                                    title="thieve the bank"
                                    time="30 mins"
                                    link="/action"

                                />
                                <Videocard
                                    img="images/background/asset-8.jpeg"
                                    title="ship of full moon"
                                    time="1hr 35mins"
                                    link="/action" />

                                <Videocard
                                    img="images/background/asset-11.jpeg"
                                    title="the giant ship"
                                    time="1hr 02mins"
                                    link="/action"
                                />

                                <Videocard
                                    img="images/background/asset-12.jpeg"
                                    title="common man’s idea"
                                    time="1hr 02mins"
                                    link="/register"
                                />

                                <Videocard
                                    img="images/background/asset-13.jpeg"
                                    title="the jin’s friend"
                                    time="1hr 49mins"
                                    link="/register"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Video;