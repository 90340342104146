import React from 'react';
import Loader from '../../Loader';
import Header from '../../Header';
import Breadcrumb from '../../Breadcrumb';
import Error404 from './Eroor404';

import Footer from '../../Footer';
import Scrolltop from '../../Scrolltop';
const Home = () => {
    return(
        <>
       <Loader />
       <Header/>
       <Breadcrumb titles=" Eroor 404 " 
       title="Error 404"/>
       <Error404 />
      
       <Footer />
        <Scrolltop/>
       </>
    );
}
export default Home;