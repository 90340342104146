import React from 'react';

const Login = () => {
    return(
        <section className="position-relative pb-0">
        <div className="gen-login-page-background" style={{backgroundImage: "url('images/background/asset-54.jpg')"}}></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center">
                        <form name="pms_login" id="pms_login" action="#">
                            <h4>Sign In</h4>
                            <p className="login-username">
                                <label for="user_login">Username or Email Address</label>
                                <input type="text" name="log" id="user_login" className="input"size="20"/>
                            </p>
                            <p className="login-password">
                                <label for="user_pass">Password</label>
                                <input type="password" name="pwd" id="user_pass" className="input"size="20"/>
                            </p>
                            <p className="login-remember">
                                <label>
                                    <input name="rememberme" type="checkbox" id="rememberme"/> Remember
                                    Me </label>
                            </p>
                            <p className="login-submit">
                                <input type="submit" name="wp-submit" id="wp-submit" className="button button-primary"
                                    value="Log In"/>
                                <input type="hidden" name="redirect_to"/>
                            </p>
                            <input type="hidden" name="pms_login" value="1"/><input type="hidden" name="pms_redirect"/><a
                                href="/register">Register</a> | <a href="/recover">Lost your
                                password?</a>
                          
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}
export default Login;