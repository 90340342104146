import React from 'react';
import Videocard from './Videocard';
const Video = () => {
    return (
        <section className="pt-0 gen-section-padding-2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <h4 className="gen-heading-title">Top Regional Shows</h4>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block">
                        <div className="gen-movie-action">
                            <div className="gen-btn-container text-right">
                                <a href="/tvshowpagination" className="gen-button gen-button-flat">
                                    <span className="text">More Videos</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="gen-style-2">
                            <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true" data-desk_num="4"
                                data-lap_num="3" data-tab_num="2" data-mob_num="1" data-mob_sm="1" data-autoplay="false"
                                data-loop="false" data-margin="30">



                                <Videocard
                                    img="images/background/asset-14.jpeg"
                                    title="ghost of sky"
                                    season="1 season"
                                    cat="Adventure"
                                />
                                <Videocard
                                    img="images/background/asset-15.jpeg"
                                    title="love in 21st"
                                    season="2 season"
                                    cat="Action"
                                />
                                <Videocard
                                    img="images/background/asset-16.jpeg"
                                    title="family love"
                                    season="0 season"
                                    cat="Drama"
                                />

                                <Videocard
                                    img="images/background/asset-16.jpeg"
                                    title="family love"
                                    season="0 season"
                                    cat="Drama"
                                />

                                <Videocard
                                    img="images/background/asset-17.jpeg"
                                    title="dance nation dance"
                                    season="3 season"
                                    cat="Comedy"
                                />

                                <Videocard
                                    img="images/background/asset-18.jpeg"
                                    title="vacation life"
                                    season="1 season"
                                    cat="Action"
                                />

                                <Videocard
                                    img="images/background/asset-19.jpeg"
                                    title="dream of dragons"
                                    season="1 season"
                                    cat="Drama"
                                />

                                <Videocard
                                    img="images/background/asset-20.jpeg"
                                    title="command in your hand"
                                    season="1 season"
                                    cat="Comedy"
                                />

                                <Videocard
                                    img="images/background/asset-21.jpeg"
                                    title="stories of the dark"
                                    season="1 season"
                                    cat="Biography"
                                />

                                <Videocard
                                    img="images/background/asset-22.jpeg"
                                    title="3 Hackers:TBG"
                                    season="1 season"
                                    cat="Drama"
                                />

                                <Videocard
                                    img="images/background/asset-13.jpeg"
                                    title="friend of jin"
                                    season="1 season"
                                    cat="Drama"
                                />

                                                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Video;