import React from 'react';

const Library = () => {
    return(
        <section className="gen-section-padding-3 gen-library">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <form>
                        <div className="gen-register-form">
                            <h2>Register</h2>
                            <label>Email address *</label>
                            <input type="email" name="email" value=""/>
                            <div className="form-button">
                                <button type="submit" name="register" value="Register">Register</button>
                            </div>
                        </div>

                        <div className="gen-login-form">
                            <h2>Login</h2>
                            <label>Username or email address *</label>
                            <input type="text" name="username" value=""/>
                            <label>Password *</label>
                            <input type="password" name="password"/>
                            <div className="form-button">
                                <button type="submit" name="register" value="Register" className="mb-0">LOG IN</button>
                                <input type="checkbox" name="checkbox" value="" className="mb-0"/>
                                <label className="d-inline-block"> Remember me</label>
                            </div>
                        </div>
                        <div className="gen-recover-password">
                            <a href="/recover">Lost your password?</a>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </section>
    );
}
export default Library;