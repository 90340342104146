import React from 'react';
import Videocard from './Videocard';

const Video = () => {
    return (
        <section className="gen-section-padding-2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <h4 className="gen-heading-title">Trending Videos</h4>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block">
                        <div className="gen-movie-action">
                            <div className="gen-btn-container text-right">
                                <a href="/tvshowpagination" className="gen-button gen-button-flat">
                                    <span className="text">More Videos</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="gen-style-2">
                            <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true"
                                data-desk_num="4" data-lap_num="3" data-tab_num="2" data-mob_num="1" data-mob_sm="1"
                                data-autoplay="false" data-loop="false" data-margin="30">

                                <Videocard
                                    img="images/background/asset-41.jpg"
                                    title="sefozie world"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Videocard
                                    img="images/background/asset-36.jpg"
                                    title="war hands"
                                    week="3 weeks"
                                    type="Animation"

                                />

                                <Videocard
                                    img="images/background/asset-38.jpg"
                                    title="MGX-fighter"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Videocard
                                    img="images/background/asset-36.jpg"
                                    title="voho the skull land"
                                    week="3 weeks"
                                    type="Horror"

                                />
                                <Videocard
                                    img="images/background/asset-37.jpg"
                                    title="Bigfoot Silva"
                                    week="2 years"
                                    type="Action"

                                />

                                <Videocard
                                    img="images/background/asset-35.jpg"
                                    title="Robot War"
                                    week="2 years"
                                    type="Tennis"

                                />

                                <Videocard
                                    img="images/background/asset-2.jpg"
                                    title="nature world"
                                    week="2 years"
                                    type="Traveling"

                                />
                              
                                <Videocard
                                    img="images/background/asset-39.jpg"
                                    title="AI:world"
                                    week="2 years"
                                    type="cricket"

                                />
                                 <Videocard
                                    img="images/background/asset-5.jpeg"
                                    title="Horse Ride"
                                    week="2 years"
                                    type="cricket"

                                />
                                  <Videocard
                                    img="images/background/asset-11.jpeg"
                                    title="Big Machine"
                                    week="2 years"
                                    type="Traveling"

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Video;