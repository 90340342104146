import React from 'react';
import Videocard from './Videocard';

const Video = () => {
    return (
        <section className="gen-section-padding-2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <h4 className="gen-heading-title">All Time Hits Videos</h4>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block">
                        <div className="gen-movie-action">
                            <div className="gen-btn-container text-right">
                                <a href="/tvshowpagination" className="gen-button gen-button-flat">
                                    <span className="text">More Videos</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="gen-style-2">
                            <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true"
                                data-desk_num="4" data-lap_num="3" data-tab_num="2" data-mob_num="1" data-mob_sm="1"
                                data-autoplay="false" data-loop="false" data-margin="30">

                                <Videocard
                                    img="images/background/asset-10.jpeg"
                                    title="skull’s world"
                                    week="3 years"
                                    type="Horror"

                                />
                                <Videocard
                                    img="images/background/asset-55.jpg"
                                    title="AKX-Gun"
                                    week="2 years"
                                    type="Games"

                                />

                                <Videocard
                                    img="images/background/asset-56.jpg"
                                    title="cycle rider"
                                    week="2 years"
                                    type="Games"

                                />
                                <Videocard
                                    img="images/background/asset-57.jpg"
                                    title="childhood"
                                    week="2 years"
                                    type="Tennis"

                                />
                                <Videocard
                                    img="images/background/asset-58.jpg"
                                    title="train life"
                                    week="2 years"
                                    type="Action"

                                />

                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Video;