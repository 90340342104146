import React from 'react';
import Imagecard from './Imagecard';
const Source = () => {
    return(
        <section className="pt-0 pb-0 gen-section-padding-2 home-singal-silder">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="gen-banner-movies">
                        <div className="owl-carousel owl-loaded owl-drag" data-dots="true" data-nav="true" data-desk_num="1"
                            data-lap_num="1" data-tab_num="1" data-mob_num="1" data-mob_sm="1" data-autoplay="false"
                            data-loop="false" data-margin="30">
                           
                          <Imagecard 
                          img="images/background/asset-59.jpg"
                          time="2 years"
                          type="Tennis"
                          views="25 Views"
                          />
                            <Imagecard 
                          img="images/background/asset-60.jpg"
                          time="2 years"
                          type="Action"
                          views="18 Views"
                          />
                            <Imagecard 
                          img="images/background/asset-10.jpeg"
                          time="2 years"
                          type="Horror"
                          views="17 Views"
                          />
                          
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}
export default Source;