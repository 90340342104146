import React from 'react';
import Moviecard from './Moviecard';
const Movies = () => {
    return (
        <section className="gen-section-padding-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">

                        <Moviecard
                                    img="images/background/asset-41.jpg"
                                    title="sefozie world"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Moviecard
                                    img="images/background/asset-36.jpg"
                                    title="war hands"
                                    week="3 weeks"
                                    type="Animation"

                                />

                                <Moviecard
                                    img="images/background/asset-38.jpg"
                                    title="MGX-fighter"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Moviecard
                                    img="images/background/asset-36.jpg"
                                    title="voho the skull land"
                                    week="3 weeks"
                                    type="Horror"

                                />
                                <Moviecard
                                    img="images/background/asset-37.jpeg"
                                    title="Bigfoot Silva"
                                    week="2 years"
                                    type="Action"

                                />

                                <Moviecard
                                    img="images/background/asset-35.jpg"
                                    title="Robot War"
                                    week="2 years"
                                    type="Tennis"

                                />

                                <Moviecard
                                    img="images/background/asset-2.jpg"
                                    title="nature world"
                                    week="2 years"
                                    type="Traveling"

                                />
                              
                                <Moviecard
                                    img="images/background/asset-39.jpg"
                                    title="AI:world"
                                    week="2 years"
                                    type="cricket"

                                />
                                 <Moviecard
                                    img="images/background/asset-5.jpeg"
                                    title="Horse Ride"
                                    week="2 years"
                                    type="cricket"

                                />
                                  <Moviecard
                                    img="images/background/asset-11.jpeg"
                                    title="Big Machine"
                                    week="2 years"
                                    type="Traveling"

                                />
                                       <Moviecard
                                    img="images/background/asset-41.jpg"
                                    title="sefozie world"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Moviecard
                                    img="images/background/asset-36.jpg"
                                    title="war hands"
                                    week="3 weeks"
                                    type="Animation"

                                />

                                <Moviecard
                                    img="images/background/asset-38.jpg"
                                    title="MGX-fighter"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Moviecard
                                    img="images/background/asset-36.jpg"
                                    title="voho the skull land"
                                    week="3 weeks"
                                    type="Horror"

                                />
                                <Moviecard
                                    img="images/background/asset-37.jpg"
                                    title="Bigfoot Silva"
                                    week="2 years"
                                    type="Action"

                                />

                                <Moviecard
                                    img="images/background/asset-35.jpg"
                                    title="Robot War"
                                    week="2 years"
                                    type="Tennis"

                                />

                                <Moviecard
                                    img="images/background/asset-2.jpg"
                                    title="nature world"
                                    week="2 years"
                                    type="Traveling"

                                />
                              
                                <Moviecard
                                    img="images/background/asset-39.jpg"
                                    title="AI:world"
                                    week="2 years"
                                    type="cricket"

                                />
                                 <Moviecard
                                    img="images/background/asset-5.jpeg"
                                    title="Horse Ride"
                                    week="2 years"
                                    type="cricket"

                                />
                                  <Moviecard
                                    img="images/background/asset-11.jpeg"
                                    title="Big Machine"
                                    week="2 years"
                                    type="Traveling"

                                />

                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                    <div className="gen-pagination">
                        <nav aria-label="Page navigation">
                            <ul className="page-numbers">
                                <li><span aria-current="page" className="page-numbers current">1</span></li>
                                <li><a className="page-numbers" href="/">2</a></li>
                                <li><a className="page-numbers" href="/">3</a></li>
                                <li><a className="next page-numbers" href="/">Next page</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}
export default Movies;