import React from 'react';
import Imagecard from './Imagecard';


const Source = () => {
    return(
        <section className="gen-section-padding-2 pt-0 pb-0 home-singal-silder">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="gen-banner-movies">
                        <div className="owl-carousel owl-loaded owl-drag" data-dots="true" data-nav="false"
                            data-desk_num="1" data-lap_num="1" data-tab_num="1" data-mob_num="1" data-mob_sm="1"
                            data-autoplay="true" data-loop="false" data-margin="30">
                            
                            <Imagecard
                            img="images/background/asset-19.jpeg"
                            title="Dream Of Dargons"
                            seo="1 Season"
                            ep="5 Episodes"
                            dur="2019 to 2020"
                            type="Drama"
                            />
                            <Imagecard
                            img="images/background/asset-20.jpeg"
                            title="Command In Your Hand"
                            seo="1 Season"
                            ep="3 Episodes"
                            dur="2013"
                            type="Comedy"
                            />
                             <Imagecard
                            img="images/background/asset-59.jpeg"
                            title="Live In My Dreams"
                            seo="0 Season"
                            ep="0 Episodes"
                            dur=""
                            type="Comedy"
                            />
                            
                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}
export default Source;