import React from 'react';
import Imagecard from './Imagecard';
const Scratch = () => {
    return(
        <section className="pt-0 gen-section-padding-2 home-singal-silder">
        <div className="container">
           <div className="row">
              <div className="col-12">
                 <div className="gen-banner-movies">
                    <div className="owl-carousel owl-loaded owl-drag" data-dots="true" data-nav="false" data-desk_num="1"
                       data-lap_num="1" data-tab_num="1" data-mob_num="1" data-mob_sm="1" data-autoplay="false"
                       data-loop="false" data-margin="30">
                      <Imagecard 
                      img="images/background/asset-20.jpeg"
                      title="Command in your hand"
                      season="1 Season"
                      ep="3 Episode"
                      dur="2013"
                      cat="Comedy"
                      />
                         <Imagecard 
                      img="images/background/asset-21.jpeg"
                      title="stories of the dark"
                      season="1 Season"
                      ep="5 Episode"
                      dur="2015 to 2016"
                      cat="Comedy"
                      />
                        <Imagecard 
                      img="images/background/asset-37.jpeg"
                      title="Against Beast"
                      season="1 Season"
                      ep="1 Episode"
                      dur="2017 to 2018"
                      cat="Drama"
                      />
                       
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
    );
}
export default Scratch;