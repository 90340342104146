import React from 'react';
import Loader from '../../Loader';
import Header from '../../Header';
import Banner from './content/Banner';
import Videosection1 from './content/Videosection/Videosection-one';
import Videosection2 from './content/Videosection/Videosection-two';
import Imagepart from './content/Imagepart';
import Videosection3 from './content/Videosection/Videosection-three';
import Footer from '../../Footer';

import Scrolltop from '../../Scrolltop';
const Home = () => {
    return (
        <>
            <Loader />
            <Header />
            <Banner />
            <Videosection1 />
            <Videosection2 />
            <Imagepart />
            <Videosection3 />
            <Footer />
            <Scrolltop />
        </>
    );
}
export default Home;