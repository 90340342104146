import React from 'react';

const Header = () => {
   const dat = () => { return false; }
    return(
        <header id="gen-header" className="gen-header-style-1 gen-has-sticky">
        <div className="gen-bottom-header">
           <div className="container">
              <div className="row">
                 <div className="col-lg-12">
                    <nav className="navbar navbar-expand-lg navbar-light">
                       <a className="navbar-brand" href="/">
                          <img className="img-fluid logo" src="images/logo-1.png" alt="streamlab navbar logo"/>
                       </a>
                       <div className="collapse navbar-collapse" id="navbarSupportedContent">
                          <div id="gen-menu-contain" className="gen-menu-contain">
                             <ul id="gen-main-menu" className="navbar-nav ml-auto">
                                <li className="menu-item">
                                   <a href="/" aria-current="page">Home</a>
                                   <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                   <ul className="sub-menu">
                                      <li className="menu-item">
                                         <a href="/" aria-current="page">Main Home</a>
                                      </li>
                                      <li className="menu-item">
                                         <a href="/movieshome" aria-current="page">Movies Home</a>
                                      </li>
                                      <li className="menu-item">
                                         <a href="/tvshowshome" aria-current="page">Tv Shows Home</a>
                                      </li>
                                      <li className="menu-item">
                                         <a href="/videohome" aria-current="page">Video Home</a>
                                      </li>
                                   </ul>
                                </li>
                                <li className="menu-item">
                                   <a href="/">Movies</a>
                                   <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                   <ul className="sub-menu">
                                      
                                      <li className="menu-item menu-item-has-children">
                                         <a href="/">Movies Style</a>
                                         <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                         <ul className="sub-menu">
                                            <li className="menu-item">
                                               <a href="/moviestyleone">Style 1</a>
                                            </li>
                                            <li className="menu-item">
                                               <a href="/moviestyletwo">Style 2</a>
                                            </li>
                                            <li className="menu-item">
                                               <a href="/moviestylethree">Style 3</a>
                                            </li>
                                         </ul>
                                      </li>
                                      <li className="menu-item">
                                         <a href="/singlemovie">Single Movie</a>
                                      </li>
                                   </ul>
                                </li>
                                <li className="menu-item">
                                   <a href="/">Tv Shows</a>
                                   <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                   <ul className="sub-menu">
                                      
                                      <li className="menu-item menu-item-has-children">
                                         <a href="/">Tv Shows Style</a>
                                         <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                         <ul className="sub-menu">
                                            <li className="menu-item">
                                               <a href="/tvshowstyleone">Style 1</a>
                                            </li>
                                            <li className="menu-item">
                                               <a href="/tvshowstyletwo">Style 2</a>
                                            </li>
                                            <li className="menu-item">
                                               <a href="/tvshowstylethree">Style 3</a>
                                            </li>
                                         </ul>
                                      </li>
                                      <li className="menu-item">
                                         <a href="/singletvshow">Single Tv Shows</a>
                                      </li>
                                      <li className="menu-item">
                                         <a href="/singleepisod">Single Episode</a>
                                      </li>
                                   </ul>
                                </li>
                                <li className="menu-item">
                                   <a href="/">Video</a>
                                   <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                   <ul className="sub-menu">
                                      
                                      <li className="menu-item menu-item-has-children">
                                         <a href="/">Videos Style</a>
                                         <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                         <ul className="sub-menu">
                                            <li className="menu-item">
                                               <a href="/videostyleone">Style 1</a>
                                            </li>
                                            <li className="menu-item">
                                               <a href="/videostyletwo">Style 2</a>
                                            </li>
                                            <li className="menu-item">
                                               <a href="/videostylethree">Style 3</a>
                                            </li>
                                         </ul>
                                      </li>
                                      <li className="menu-item">
                                         <a href="/singlevideo">Single videos</a>
                                      </li>
                                   </ul>
                                </li>
                                <li className="menu-item">
                                   <a href="/">Pages</a>
                                   <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                   <ul className="sub-menu">
                                      <li className="menu-item menu-item-has-children">
                                         <a href="/">Blog</a>
                                         <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                         <ul className="sub-menu">
                                            <li className="menu-item menu-item-has-children">
                                               <a href="/">Blog With Sidebar</a>
                                               <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                               <ul className="sub-menu">
                                                  <li className="menu-item">
                                                     <a href="/blogleft">blog left sidebar</a>
                                                  </li>
                                                  <li className="menu-item">
                                                     <a href="/blogright">blog right sidebar</a>
                                                  </li>
                                               </ul>
                                            </li>
                                         </ul>
                                      </li>
                                      <li className="menu-item menu-item-has-children">
                                         <a href="/">Pricing</a>
                                         <i className="fa fa-chevron-down gen-submenu-icon"></i>
                                         <ul className="sub-menu">
                                            <li className="menu-item">
                                               <a href="/Pricestyleone">Style 1</a>
                                            </li>
                                            <li className="menu-item">
                                               <a href="/Pricestyletwo">Style 2</a>
                                            </li>
                                         </ul>
                                      </li>
                                      <li className="menu-item">
                                         <a href="/contactus">Contact Us</a>
                                      </li>
                                   </ul>
                                </li>
                             </ul>
                          </div>
                       </div>
                       <div className="gen-header-info-box">
                          <div className="gen-menu-search-block">
                             <a href="#!" onClick={dat} id="gen-seacrh-btn"><i className="fa fa-search"></i></a>
                             <div className="gen-search-form">
                                <form role="search" onSubmit={dat} method="get" className="search-form" >
                                   <label>
                                      <span className="screen-reader-text"></span>
                                      <input type="search" className="search-field" placeholder="Search …" name="s"/>
                                   </label>
                                   <button type="submit" className="search-submit"><span
                                         className="screen-reader-text"></span></button>
                                </form>
                             </div>
                          </div>
                          <div className="gen-account-holder">
                             <a href="#!" onClick={dat} id="gen-user-btn"><i className="fa fa-user"></i></a>
                             <div className="gen-account-menu">
                                <ul className="gen-account-menu">
                                   {/* <!-- Pms Menu --> */}
                                   <li>
                                      <a href="/login"><i className="fas fa-sign-in-alt"></i>
                                         login </a>
                                   </li>
                                   <li>
                                      <a href="/register"><i className="fa fa-user"></i>
                                         Register </a>
                                   </li>
                                  
                                </ul>
                             </div>
                          </div>
                          <div className="gen-btn-container">
                             <a href="/register" className="gen-button">
                                <div className="gen-button-block">
                                   <span className="gen-button-line-left"></span>
                                   <span className="gen-button-text">Subscribe</span>
                                </div>
                             </a>
                          </div>
                       </div>
                       <button className="navbar-toggler" type="button" data-toggle="collapse"
                          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                          aria-expanded="false" aria-label="Toggle navigation">
                          <i className="fas fa-bars"></i>
                       </button>
                    </nav>
                 </div>
              </div>
           </div>
        </div>
     </header>
    );
}
export default Header;