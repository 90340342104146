import React from 'react';
import Slidercard from './Slidercard';
import Bigslidercard from './Bigslidercard';
const Scratch = () => {
    return (
        <section className="gen-section-padding-2 pt-0 pb-0">
            <div className="container">
                <div className="home-singal-silder">
                    <div className="gen-nav-movies gen-banner-movies">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="slider slider-for">
                                    {/*    <!-- Slider Items --> */}

                                   <Bigslidercard 
                                   img="images/background/asset-4.jpeg"
                                   title="thieve the bank" />
                                   
                                   <Bigslidercard 
                                   img="images/background/asset-23.jpeg"
                                   title="Love your life" />
                                   
                                   <Bigslidercard 
                                   img="images/background/asset-24.jpeg"
                                   title="The Last Witness" />
                                   
                                   <Bigslidercard 
                                    img="images/background/asset-25.jpeg"
                                   title="Fight For Life" />
                                   
                                   
                                    {/*     <!-- Slider Items --> */}
                                </div>
                                <div className="slider slider-nav">
                                    <Slidercard
                                        img="images/background/asset-4.jpeg"
                                        title="thieve the bank"
                                        time="30min"
                                    />
                                    <Slidercard
                                        img="images/background/asset-23.jpeg"
                                        title="Love your life"
                                        time="1hr 37mins"
                                    />
                                    <Slidercard
                                        img="images/background/asset-24.jpeg"
                                        title="The Last Witness"
                                        time="1hr 37mins"
                                    />
                                     <Slidercard
                                        img="images/background/asset-25.jpeg"
                                        title="Fight For Life"
                                        time="1hr 37mins"
                                    />
                                 
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Scratch;