import React from 'react';
import Loader from '../../Loader';
import Header from '../../Header';
import Breadcrumb from '../../Breadcrumb';
import Tvshowplaylist from './Tvshowplaylist';

import Footer from '../../Footer';
import Scrolltop from '../../Scrolltop';
const Home = () => {
    return(
        <>
       <Loader />
       <Header/>
       <Breadcrumb titles="Tv show Play list"
       title="Tv show Play list" />
       <Tvshowplaylist />
      
       <Footer />
        <Scrolltop/>
       </>
    );
}
export default Home;