import React from 'react';

const Scratch = (prop) => {
    return(
      
        <div className="slider-nav-contain">
           <div className="gen-nav-img">
              <img src={prop.img} alt="steamlab"/>
           </div>
           <div className="movie-info">
              <h3>{prop.title}</h3>
              <div className="gen-movie-meta-holder">
                 <ul>
                    <li>{prop.time}</li>
                    <li>
                       <a href="/action">
                          Action </a>
                    </li>
                 </ul>
              </div>
           </div>
        </div>
    );
}
export default Scratch;
