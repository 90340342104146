import React from 'react';
import Blogcard from './Blogcard';
const Blogpageleft = () => {
    return(
        <section>
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-md-12 order-2 order-xl-1 mt-4 mt-xl-0">
                    <div className="widget widget_search">
                        <form role="search" method="get" className="search-form" action="#">
                            <label>
                                <input type="search" className="search-field" placeholder="Search …" value="" name="s"/>
                            </label>
                            <button type="submit" className="search-submit"></button>
                        </form>
                    </div>
                    <div className="widget widget_recent_entries">
                        <h2 className="widget-title">Recent Posts</h2>
                        <ul>
                            <li>
                                <a href="/">Stream like a pro with (free) live encoding software</a>
                            </li>
                            <li>
                                <a href="/">What it takes to live stream a global event</a>
                            </li>
                            <li>
                                <a href="/">Top streaming equipment based on your budget</a>
                            </li>
                            <li>
                                <a href="/">How to record your live streams</a>
                            </li>
                            <li>
                                <a href="/">Continue Watching Video on Demand</a>
                            </li>
                        </ul>
                    </div>
                    <div className="widget widget_recent_comments">
                        <h2 className="widget-title">Recent Comments</h2>
                        <ul id="recentcomments">
                            <li className="recentcomments"><span className="comment-author-link"><a href="/"
                                        rel="external nofollow ugc" className="url pl-0">admin</a></span> on <a href="/"
                                    className="pl-0">Love In 21st</a></li>
                            <li className="recentcomments"><span className="comment-author-link"><a href="/"
                                        rel="external nofollow ugc" className="url pl-0">admin</a></span> on <a href="/"
                                    className="pl-0">My Generation</a></li>
                            <li className="recentcomments"><span className="comment-author-link">nilofer</span> on <a href="/"
                                    className="pl-0">the giant ship</a></li>
                            <li className="recentcomments"><span className="comment-author-link">imran</span> on <a href="/"
                                    className="pl-0">Gnome Alone</a></li>
                            <li className="recentcomments"><span className="comment-author-link">imran</span> on <a href="/"
                                    className="pl-0">Kimozy-The Dragon</a></li>
                        </ul>
                    </div>
                    <div className="widget widget_archive">
                        <h2 className="widget-title">Archives</h2>
                        <ul>
                            <li><a href="/">January 2021</a></li>
                        </ul>
                    </div>
                    <div className="widget widget_categories">
                        <h2 className="widget-title">Categories</h2>
                        <ul>
                            <li className="cat-item cat-item-1"><a href="/">Uncategorized</a></li>
                        </ul>
                    </div>
                    <div className="widget widget_meta">
                        <h2 className="widget-title">Meta</h2>
                        <ul>
                            <li><a href="/">Log in</a></li>
                            <li><a href="/">Entries feed</a></li>
                            <li><a href="/">Comments feed</a></li>
                            <li><a href="/">WordPress.org</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-9 col-md-12 order-1 order-xl-2">
                    <div className="gen-blog gen-blog-col-1">
                        <div className="row">
                           
                           <Blogcard 
                           img="images/background/asset-19.jpeg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />
                                
                                <Blogcard 
                           img="images/background/asset-2.jpg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />     
                           <Blogcard 
                           img="images/background/asset-4.jpeg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />     
                           <Blogcard 
                           img="images/background/asset-11.jpeg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />
                              <Blogcard 
                           img="images/background/asset-47.jpeg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />
                              <Blogcard 
                           img="images/background/asset-26.jpeg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />
                           
                           <Blogcard 
                           img="images/background/asset-45.jpg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />
                           
                           <Blogcard 
                           img="images/background/asset-42.jpg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />
                           
                           <Blogcard 
                           img="images/background/asset-43.jpg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />
                           
                           
                           <Blogcard 
                           img="images/background/asset-49.jpg"
                           post="admin"
                           date="January 25, 2021"
                           cat="Uncategorized"
                           title="Stream like a pro with (free) live
                           encoding software"
                           des="There are many variations of passages of Lorem Ipsum available, but the
                           majority have suffered alteration in some form, by injected humour, or
                           randomised words which don’t look even slightly believable."
                           />
                           
                           
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="gen-pagination">
                                    <nav aria-label="Page navigation">
                                        <ul className="page-numbers">
                                            <li><span aria-current="page" className="page-numbers current">1</span></li>
                                            <li><a className="page-numbers" href="/">2</a></li>
                                            <li><a className="next page-numbers" href="/">Next page</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}
export default Blogpageleft;