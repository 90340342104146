import React from 'react';

const Map = () => {

    return (
        <>
            <section className="gen-section-padding-3 gen-top-border">
                <div className="container container-2">
                    <div className="row">
                        <div className="col-xl-6">
                            <h2 className="mb-5">get in touch</h2>
                            <form >
                                <div className="row gt-form">
                                    <div className="col-md-6 mb-4"><input type="text" name="fname" placeholder="Your Name" />
                                    </div>
                                    <div className="col-md-6 mb-4"><input type="text" name="email" placeholder="Email" /></div>
                                    <div className="col-md-6 mb-4"><input type="text" name="cell"  placeholder="Cell Phone" />
                                    </div>
                                    <div className="col-md-6 mb-4"><input type="text" name="venue" placeholder="Venue" /></div>
                                    <div className="col-md-12 mb-4"><textarea name="msg" rows="6"  placeholder="Your Message"></textarea><br />
                                        <input type="submit" value="Send" className="mt-4" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-xl-6">
                            <div style={{ width: "100%" }}><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119239.01614740983!2d72.87529447464962!3d20.94370840854599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0f795909b2c77%3A0x1494cfa5f558f3dd!2sNavsari%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1616820232806!5m2!1sen!2sin" width="600" height="600" style={{border: "0"}} title="map" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Map;