import React from 'react';

const Bigslidercard = (prop) => {
    return(
        <div className="slider-item" style={{ background: `url(${prop.img})` }}>
        <div className="gen-slick-slider h-100">
            <div className="gen-movie-contain h-100">
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-lg-6">
                            <div className="gen-movie-info">
                                <h3>{prop.title}</h3>
                                <p>Streamlab is a long established fact that a reader will be distracted by
                                the readable content of a page when Streamlab at its layout Streamlab.
             </p>

                            </div>
                            <div className="gen-movie-action">
                                <div className="gen-btn-container button-1">
                                    <a className="gen-button" href="/" tabIndex="0">
                                        <i aria-hidden="true" className="ion ion-play"></i>
                                        <span className="text">Play Now</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default Bigslidercard;