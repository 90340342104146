import React from 'react';
import Videocard from './Videocard';

const Video = () => {
    return (
        <section className="gen-section-padding-2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <h3 className="gen-heading-title">Trading Tv Shows</h3>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block">
                        <div className="gen-movie-action">
                            <div className="gen-btn-container text-right">
                                <a href="/tvshowpagination" className="gen-button gen-button-flat">
                                    <span className="text">More Videos</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="gen-style-1">
                            <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true"
                                data-desk_num="4" data-lap_num="3" data-tab_num="2" data-mob_num="1" data-mob_sm="1"
                                data-autoplay="false" data-loop="false" data-margin="30">

                             
                                <Videocard
                                    img="images/background/asset-15.jpeg"
                                    title="Love In 21st"
                                    seo="2 Season"
                                    cat="Action"
                                />
                                 <Videocard
                                    img="images/background/asset-77.jpg"
                                    title="Play For Life"
                                    seo="1 Season"
                                    cat="Action"
                                />
                                <Videocard
                                    img="images/background/asset-62.jpg"
                                    title="Kimu-The pet elephant"
                                    seo="0 Season"
                                    cat="Action"
                                />


                                <Videocard
                                    img="images/background/asset-37.jpeg"
                                    title="Against Beast"
                                    seo="2 Season"
                                    cat="Drama"
                                />
                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Video;