import React from 'react';

const Scratch = () => {
    return(
        <section className="pt-section">
      <div className="container">
         <div className="row">
            <div className="col-sm-12 text-center">
               <div className="pt-error-block">
                  <div className="pt-errot-text">404</div>
                  <h2>Oops! This Page is Not Found.</h2>
                  <p>Please go back to home and try to find out once again.</p>
                  <div className="gen-btn-container">
                                <a href="/" className="gen-button gen-button-flat">
                                    <span className="text">Back to home</span>
                                </a>
                            </div>
             
               </div>
            </div>
         </div>
      </div>
   </section>
    );
}
export default Scratch;