import React from 'react';
import Episodcard from './Episodcard';
import Tvshowcard from './Tvshowcard';

const Singletvshow = () => {
    return (
        <>

            <section className="gen-section-padding-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="gen-episode-wrapper style-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="gen-video-holder">
                                            <iframe title="frem" width="100%" height="550px" src="https://www.youtube.com/embed/LXb3EKWsInQ"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="single-episode">
                                            <div className="gen-single-tv-show-info">
                                                <h2 className="gen-title">Looking her</h2>
                                                <div className="gen-single-meta-holder">
                                                    <ul>
                                                        <li>40min</li>
                                                        <li>
                                                            <i className="fas fa-eye">
                                                            </i>
                                                            <span>212 Views</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p>Streamlab is a long established fact that a reader will be distracted by the
                                                readable content of a page when Streamlab at its layout. The point of using
                                                Lorem Streamlab is that it has a more-or-less normal distribution of
                                            Streamlab as opposed Streamlab.</p>
                                                <div className="gen-socail-share">
                                                    <h4 className="align-self-center">Social Share :</h4>
                                                    <ul className="social-inner">
                                                        <li><a href="/" className="facebook"><i className="fab fa-facebook-f"></i></a>
                                                        </li>
                                                        <li><a href="/" className="facebook"><i className="fab fa-instagram"></i></a>
                                                        </li>
                                                        <li><a href="/" className="facebook"><i className="fab fa-twitter"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="gen-season-holder">
                                                <ul className="nav">
                                                    <li className="nav-item">
                                                        <a className="nav-link active show" data-toggle="tab"
                                                            href="#season_0">Season 1</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-toggle="tab" href="#season_1">Season 2</a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div id="season_0" className="tab-pane active show">
                                                        <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true"
                                                            data-desk_num="4" data-lap_num="3" data-tab_num="2" data-mob_num="1"
                                                            data-mob_sm="1" data-autoplay="false" data-loop="false" data-margin="30">
                                                            <Episodcard
                                                                Epi="S01E01"
                                                            />
                                                            <Episodcard
                                                                Epi="S01E02"
                                                            />
                                                            <Episodcard
                                                                Epi="S01E03"
                                                            />
                                                            <Episodcard
                                                                Epi="S01E04"
                                                            />
                                                            <Episodcard
                                                                Epi="S01E05"
                                                            />


                                                        </div>
                                                    </div>
                                                    <div id="season_1" className="tab-pane">
                                                        <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true"
                                                            data-desk_num="4" data-lap_num="3" data-tab_num="2" data-mob_num="1"
                                                            data-mob_sm="1" data-autoplay="false" data-loop="false" data-margin="30"
                                                            data-rewing="false">

                                                            <Episodcard
                                                                Epi="S01E01"
                                                            />
                                                            <Episodcard
                                                                Epi="S01E02"
                                                            />
                                                            <Episodcard
                                                                Epi="S01E03"
                                                            />
                                                            <Episodcard
                                                                Epi="S01E04"
                                                            />
                                                            <Episodcard
                                                                Epi="S01E05"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="pm-inner">
                                                        <div className="gen-more-like">
                                                            <h5 className="gen-more-title">More Like This</h5>
                                                            <div className="row post-loadmore-wrapper">


                                                                <Tvshowcard
                                                                    img="images/background/asset-14.jpeg"
                                                                    title="Ghost of Sky"
                                                                    seo="1 Season"
                                                                    cat="Action"
                                                                />

                                                                <Tvshowcard
                                                                    img="images/background/asset-16.jpeg"
                                                                    title="Family Love"
                                                                    seo="0 Season"
                                                                    cat="Action"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-17.jpeg"
                                                                    title="Dance nation Dance"
                                                                    seo="0 Season"
                                                                    cat="Comady"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-18.jpeg"
                                                                    title="Vacation Life"
                                                                    seo="1 Season"
                                                                    cat="Adventure"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-19.jpeg"
                                                                    title="Dream Of Dargons"
                                                                    seo="1 Season"
                                                                    cat="Drama"
                                                                />

                                                                <Tvshowcard
                                                                    img="images/background/asset-20.jpeg"
                                                                    title="Command In Your Hand"
                                                                    seo="1 Season"
                                                                    cat="Comedy"
                                                                />

                                                                <Tvshowcard
                                                                    img="images/background/asset-21.jpeg"
                                                                    title="Stories Of the Dark"
                                                                    seo="1 Season"
                                                                    cat="Biography"
                                                                />

                                                                <Tvshowcard
                                                                    img="images/background/asset-13.jpeg"
                                                                    title="Friend Of Jin"
                                                                    seo="1 Season"
                                                                    cat="Drama"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-22.jpeg"
                                                                    title="3 Hacker:TBG"
                                                                    seo="1 Season"
                                                                    cat="Drama"
                                                                />

                                                                <Tvshowcard
                                                                    img="images/background/asset-77.jpg"
                                                                    title="Play For Life"
                                                                    seo="1 Season"
                                                                    cat="Action"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-62.jpg"
                                                                    title="Kimu-The pet elephant"
                                                                    seo="0 Season"
                                                                    cat="Action"
                                                                />


                                                                <Tvshowcard
                                                                    img="images/background/asset-37.jpeg"
                                                                    title="Against Beast"
                                                                    seo="2 Season"
                                                                    cat="Drama"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-19.jpeg"
                                                                    title="Dream Of Dargons"
                                                                    seo="1 Season"
                                                                    cat="Adventure"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-77.jpg"
                                                                    title="Play For Life"
                                                                    seo="1 Season"
                                                                    cat="Action"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-35.jpg"
                                                                    title="War With Robots"
                                                                    seo="0 Season"
                                                                    cat="Action"
                                                                />
                                                                <Tvshowcard
                                                                    img="images/background/asset-68.jpg"
                                                                    title="Story Of Mahauki Shark"
                                                                    seo="0 Season"
                                                                    cat="Action"
                                                                />



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="gen-movie-action">
                                                        <div className="gen-btn-container">
                                                            <a href="/" className="gen-button">
                                                                <span className="text">Load More</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Singletvshow;