import React from 'react';
import Videocard from './Videocard';
const Movies = () => {
    return (
        <section className="gen-section-padding-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                     
                      
                        <Videocard
                                    img="images/background/asset-41.jpg"
                                    title="sefozie world"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Videocard
                                    img="images/background/asset-36.jpg"
                                    title="war hands"
                                    week="3 weeks"
                                    type="Animation"

                                />

                                <Videocard
                                    img="images/background/asset-38.jpg"
                                    title="MGX-fighter"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Videocard
                                    img="images/background/asset-36.jpg"
                                    title="voho the skull land"
                                    week="3 weeks"
                                    type="Horror"

                                />
                                <Videocard
                                    img="images/background/asset-37.jpeg"
                                    title="Bigfoot Silva"
                                    week="2 years"
                                    type="Action"

                                />

                                <Videocard
                                    img="images/background/asset-35.jpg"
                                    title="Robot War"
                                    week="2 years"
                                    type="Tennis"

                                />

                                <Videocard
                                    img="images/background/asset-2.jpg"
                                    title="nature world"
                                    week="2 years"
                                    type="Traveling"

                                />
                              
                                <Videocard
                                    img="images/background/asset-39.jpg"
                                    title="AI:world"
                                    week="2 years"
                                    type="cricket"

                                />
                                 <Videocard
                                    img="images/background/asset-5.jpeg"
                                    title="Horse Ride"
                                    week="2 years"
                                    type="cricket"

                                />
                                  <Videocard
                                    img="images/background/asset-11.jpeg"
                                    title="Big Machine"
                                    week="2 years"
                                    type="Traveling"

                                />
                                       <Videocard
                                    img="images/background/asset-41.jpg"
                                    title="sefozie world"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Videocard
                                    img="images/background/asset-36.jpg"
                                    title="war hands"
                                    week="3 weeks"
                                    type="Animation"

                                />

                                <Videocard
                                    img="images/background/asset-38.jpg"
                                    title="MGX-fighter"
                                    week="3 weeks"
                                    type="Adventure"

                                />
                                <Videocard
                                    img="images/background/asset-36.jpg"
                                    title="voho the skull land"
                                    week="3 weeks"
                                    type="Horror"

                                />
                                <Videocard
                                    img="images/background/asset-37.jpeg"
                                    title="Bigfoot Silva"
                                    week="2 years"
                                    type="Action"

                                />

                                <Videocard
                                    img="images/background/asset-35.jpg"
                                    title="Robot War"
                                    week="2 years"
                                    type="Tennis"

                                />

                                <Videocard
                                    img="images/background/asset-2.jpg"
                                    title="nature world"
                                    week="2 years"
                                    type="Traveling"

                                />
                              
                                <Videocard
                                    img="images/background/asset-39.jpg"
                                    title="AI:world"
                                    week="2 years"
                                    type="cricket"

                                />
                                 <Videocard
                                    img="images/background/asset-5.jpeg"
                                    title="Horse Ride"
                                    week="2 years"
                                    type="cricket"

                                />
                                  <Videocard
                                    img="images/background/asset-11.jpeg"
                                    title="Big Machine"
                                    week="2 years"
                                    type="Traveling"

                                />



                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="gen-load-more-button">
                            <div className="gen-btn-container">
                                <a className="gen-button gen-button-loadmore" href="/">
                                    <span className="button-text">Load More</span>
                                    <span className="loadmore-icon" style={{ display: "none" }}><i
                                        className="fa fa-spinner fa-spin"></i></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Movies;