import React from 'react';
import Actioncard from './Actioncard';

const Scratch = () => {
    return (
        <section className="gen-section-padding-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">

                            <Actioncard
                                img="images/background/asset-5.jpeg"
                                title="The warrior life"
                                time="2hr 00mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-4.jpeg"
                                title="Thieve the bank"
                                time="30mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-23.jpeg"
                                title="love your life"
                                time="1hr 46mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-26.jpeg"
                                title="spaceman the voyager"
                                time="1hr 32mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-24.jpeg"
                                title="The last witness"
                                time="1hr 37mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-29.jpeg"
                                title="shimu the elephant"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-33.jpeg"
                                title="black water"
                                time="1hr 44mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-8.jpeg"
                                title="shipe of full moon"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-51.jpg"
                                title="The journey of a champion"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Actioncard
                                img="images/background/asset-25.jpeg"
                                title="fight for life"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Actioncard
                                img="images/background/asset-12.jpeg"
                                title="common man's idea"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Actioncard
                                img="images/background/asset-32.jpeg"
                                title="love, simon"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-27.jpeg"
                                title="i can only imagine"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-13.jpeg"
                                title="a jin's friend"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Actioncard
                                img="images/background/asset-46.jpg"
                                title="let's solve k'y mystery"
                                time="1hr 54mins"
                                type="Action"

                            />






                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="gen-pagination">
                                    <nav aria-label="Page navigation">
                                        <ul className="page-numbers">
                                            <li><span aria-current="page" className="page-numbers current">1</span></li>
                                            <li><a className="page-numbers" href="/#">2</a></li>
                                            <li><a className="page-numbers" href="/#">3</a></li>
                                            <li><a className="next page-numbers" href="/#">Next page</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}
export default Scratch;