import React from 'react';
import Loader from '../../Loader';
import Header from '../../Header';
import Slider from './Slickslider';
import Videosection1 from './Videosection/Videosection-one';
import Videosection2 from './Videosection/Videosection-two';
import Imagepart from './Imagepart';
import Videosection3 from './Videosection/Videosection-three';
import Footer from '../../Footer';
import Scrolltop from '../../Scrolltop';
const Home = () => {
    return (
        <>
            <Loader />
            <Header />
            <Slider />
            <Videosection1 />
            <Videosection2 />
            <Imagepart />
            <Videosection3 />
            

            <Footer />
            <Scrolltop />
        </>
    );
}
export default Home;