import React from 'react';
import Moviecard from './Moviecard';

const Scratch = () => {
    return(
        <>
        <section className="gen-section-padding-3 gen-single-movie">
        <div className="container">
            <div className="row no-gutters">
                <div className="col-lg-12">
                    <div className="gen-single-movie-wrapper style-1">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="gen-video-holder">
                                    <iframe title="frem" width="100%" height="550px" src="https://www.youtube.com/embed/LXb3EKWsInQ"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullscreen></iframe>
                                </div>
                                <div className="gen-single-movie-info">
                                    <h2 className="gen-title">My Generation</h2>
                                    <div className="gen-single-meta-holder">
                                        <ul>
                                            <li className="gen-sen-rating">TV-PG</li>
                                            <li>
                                                <i className="fas fa-eye">
                                                </i>
                                                <span>237 Views</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>Streamlab is a long established fact that a reader will be distracted by the
                                        readable
                                        content of a page when Streamlab at its layout. The point of using Lorem
                                        Streamlab
                                        is that it has a more-or-less normal distribution of Streamlab as opposed
                                        Streamlab.
                                    </p>
                                    <div className="gen-after-excerpt">
                                        <div className="gen-extra-data">
                                            <ul>
                                                <li>
                                                    <span>Language :</span>
                                                    <span>English</span>
                                                </li>
                                                <li>
                                                    <span>Subtitles :</span>
                                                    <span>English</span>
                                                </li>
                                                <li>
                                                    <span>Audio Languages :</span>
                                                    <span>English</span>
                                                </li>
                                                <li><span>Genre :</span>
                                                    <span>
                                                        <a href="/">
                                                            Action, </a>
                                                    </span>
                                                    <span>
                                                        <a href="/">
                                                            Documentary </a>
                                                    </span>
                                                </li>
                                                <li><span>Run Time :</span>
                                                    <span>1hr 24 mins</span>
                                                </li>
                                                <li>
                                                    <span>Release Date :</span>
                                                    <span>14 Aug,2018</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gen-socail-share">
                                            <h4 className="align-self-center">Social Share :</h4>
                                            <ul className="social-inner">
                                                <li><a href="/" className="facebook"><i className="fab fa-facebook-f"></i></a>
                                                </li>
                                                <li><a href="/" className="facebook"><i className="fab fa-instagram"></i></a>
                                                </li>
                                                <li><a href="/" className="facebook"><i className="fab fa-twitter"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="pm-inner">
                                    <div className="gen-more-like">
                                        <h5 className="gen-more-title">More Like This</h5>
                                        <div className="row">
                                          
                                          
                                          
                                            <Moviecard
                                img="images/background/asset-5.jpeg"
                                title="The warrior life"
                                time="2hr 00mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-4.jpeg"
                                title="Thieve the bank"
                                time="30mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-23.jpeg"
                                title="love your life"
                                time="1hr 46mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-26.jpeg"
                                title="spaceman the voyager"
                                time="1hr 32mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-24.jpeg"
                                title="The last witness"
                                time="1hr 37mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-29.jpeg"
                                title="shimu the elephant"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-33.jpeg"
                                title="black water"
                                time="1hr 44mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-8.jpeg"
                                title="shipe of full moon"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-51.jpg"
                                title="The journey of a champion"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Moviecard
                                img="images/background/asset-25.jpeg"
                                title="fight for life"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Moviecard
                                img="images/background/asset-12.jpeg"
                                title="common man's idea"
                                time="1hr 54mins"
                                type="Action"

                            />

                            <Moviecard
                                img="images/background/asset-32.jpeg"
                                title="love, simon"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-27.jpeg"
                                title="i can only imagine"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-13.jpeg"
                                title="a jin's friend"
                                time="1hr 54mins"
                                type="Action"

                            />
                            <Moviecard
                                img="images/background/asset-46.jpg"
                                title="let's solve k'y mystery"
                                time="1hr 54mins"
                                type="Action"

                            />
                                   </div>         
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="gen-load-more-button">
                                                    <div className="gen-btn-container">
                                                        <a className="gen-button gen-button-loadmore" href="/">
                                                            <span className="button-text">Load More</span>
                                                            <span className="loadmore-icon" style={{display: "none"}}><i
                                                                    className="fa fa-spinner fa-spin"></i></span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    </section>
    </>
    );
}
export default Scratch;