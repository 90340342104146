import React from 'react';
import Imagecard from './Imagecard';
const Imagepart = () => {
    return(
      <section className="pt-0 pb-0 gen-section-padding-2 home-singal-silder">
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="gen-banner-movies">
                      <div className="owl-carousel owl-loaded owl-drag" data-dots="true" data-nav="false"
                          data-desk_num="1" data-lap_num="1" data-tab_num="1" data-mob_num="1" data-mob_sm="1"
                          data-autoplay="true" data-loop="false" data-margin="30">
                         
                         <Imagecard 
                         relcat="New Realease"
                         img="images/background/asset-5.jpeg"
                         title="The warrior life"
                         cat="R"
                         time="2hr 00mins"
                         ver="8.5"
                         rel="2020"  
                         feel="Action"                       
                         />
                           <Imagecard 
                         img="images/background/asset-6.jpeg"
                         title="machine war"
                         cat="U"
                         time="1hr 22mins"
                         ver="0"
                         rel="2017"  
                         feel="Action"                       
                         />
                             <Imagecard 
                         img="images/background/asset-8.jpeg"
                         title="Ship of full moon"
                         cat=" PG - 13"
                         time="1hr 35mins"
                         ver="0"
                         rel="2018"
                         Feel="Action"                         
                         />
                           <Imagecard 
                         img="images/background/asset-9.jpeg"
                         title="Rebuneka the doll"
                         cat="R"
                         time="1hr 44mins"
                         ver="0"
                         rel="2018"
                         Feel="Drama"                         
                         />
                           <Imagecard 
                         relcat="High Rated"
                         img="images/background/asset-4.jpeg"
                         title="thieve the bank"
                         cat="TV-MA"
                         time="1hr 44mins"
                         ver="9.5"
                         rel="2018"
                         Feel="Action"                         
                         />
                           <Imagecard
                         img="images/background/asset-10.jpeg"
                         title="skull of myths"
                         cat="TV-G"
                         time="1hr 24mins"
                         ver="0"
                         rel="2016"
                         Feel="Action"                         
                         />
                         <Imagecard
                       img="images/background/asset-11.jpeg"
                       title="the giant ship"
                       cat="R"
                       time="1hr 02mins"
                       ver="0"
                       rel="2017"
                       Feel="Action"                         
                       />
                        <Imagecard
                       img="images/background/asset-12.jpeg"
                       title="common man’s idea"
                       cat="PG-13"
                       time="1hr 51mins"
                       ver="0"
                       rel="2018"
                       Feel="Action"                         
                       />
                       
                       <Imagecard
                       img="images/background/asset-13.jpeg"
                       title="the jin’s friend"
                       cat="PG-13"
                       time="1hr 42mins"
                       ver="0"
                       rel="2018"
                       Feel="Action"                         
                       />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
    );
}
export default Imagepart;