import React from 'react';
import Svgfile from './Svgfile';

const Scratch = () => {
    return(
        <section className="pt-0 pb-0">
        <div className="container-fluid px-0">
            <div className="row no-gutters">
                <div className="col-12">
                    <div className="gen-banner-movies banner-style-3">
                        <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true"
                            data-desk_num="1" data-lap_num="1" data-tab_num="1" data-mob_num="1" data-mob_sm="1"
                            data-autoplay="true" data-loop="true" data-margin="0">
                            <div className="item" style={{background: "url('images/background/asset-41.jpg')"}}>
                                <div className="gen-movie-contain-style-3 h-100">
                                    <div className="container h-100">
                                        <div className="row justify-content-center h-100">
                                            <div className="col-xl-6">
                                                <a href="singlevideo" className="playBut">
                                                    {/* <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  --> */}
                                                     <Svgfile />
                                                    <span>Watch Now</span>
                                                </a>
                                                <div className="gen-movie-info">
                                                    <h3>sefozie world</h3>
                                                </div>
                                                <div className="gen-movie-meta-holder">
                                                    <ul className="gen-meta-after-title">
                                                        <li>2 weeks</li>
                                                        <li>
                                                            <a href="/adventure"><span>Adventure</span></a>
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-eye">
                                                            </i>
                                                            <span>44 Views</span>
                                                        </li>
                                                    </ul>
                                                    <p>Streamlab is a long established fact that a reader will be
                                                        distracted by the readable content of a page when Streamlab at
                                                        its layout.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item"style={{background: "url('images/background/asset-36.jpg')"}}>
                                <div className="gen-movie-contain-style-3 h-100">
                                    <div className="container h-100">
                                        <div className="row justify-content-center h-100">
                                            <div className="col-xl-6">
                                                <a href="singlevideo" className="playBut">
                                                    {/* <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  --> */}
                                                    <Svgfile />
                                                    <span>Watch Now</span>
                                                </a>
                                                <div className="gen-movie-info">
                                                    <h3>war hands</h3>
                                                </div>
                                                <div className="gen-movie-meta-holder">
                                                    <ul className="gen-meta-after-title">
                                                        <li>2 weeks</li>
                                                        <li>
                                                            <a href="/action"><span>Animation</span></a>
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-eye">
                                                            </i>
                                                            <span>43 Views</span>
                                                        </li>
                                                    </ul>
                                                    <p>Streamlab is a long established fact that a reader will be
                                                        distracted by the readable content of a page when Streamlab at
                                                        its layout.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item" style={{background: "url('images/background/asset-37.jpeg')"}}>
                                <div className="gen-movie-contain-style-3 h-100">
                                    <div className="container h-100">
                                        <div className="row justify-content-center h-100">
                                            <div className="col-xl-6">
                                                <a href="singlevideo" className="playBut">
                                                  {/*   <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  --> */}
                                                  <Svgfile />
                                                    <span>Watch Now</span>
                                                </a>
                                                <div className="gen-movie-info">
                                                    <h3>Bigfoot Silva</h3>
                                                </div>
                                                <div className="gen-movie-meta-holder">
                                                    <ul className="gen-meta-after-title">
                                                        <li>2 years</li>
                                                        <li>
                                                            <a href="/action"><span>Action</span></a>
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-eye">
                                                            </i>
                                                            <span>43 Views</span>
                                                        </li>
                                                    </ul>
                                                    <p>Streamlab is a long established fact that a reader will be
                                                        distracted by the readable content of a page when Streamlab at
                                                        its layout.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}
export default Scratch;