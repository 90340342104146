import React from 'react';

const Banner = () => {
   return (
      <section className="pt-0 pb-0">
         <div className="container-fluid px-0">
            <div className="row no-gutters">
               <div className="col-12">
                  <div className="gen-banner-movies banner-style-2">
                     <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true" data-desk_num="1"
                        data-lap_num="1" data-tab_num="1" data-mob_num="1" data-mob_sm="1" data-autoplay="true"
                        data-loop="true" data-margin="0">
                        <div className="item" style={{ background: "url('images/background/asset-1.jpeg')" }}>
                           <div className="gen-movie-contain-style-2 h-100">
                              <div className="container h-100">
                                 <div className="row flex-row-reverse align-items-center h-100">
                                    <div className="col-xl-6">
                                       <div className="gen-front-image">
                                          <img src="images/background/asset-1.jpeg" alt="owl-carousel-banner" />
                                          <a href="https://www.youtube.com/watch?v=a3ICNMQW7Ok" className="playBut popup-youtube popup-vimeo popup-gmaps">
                                             {/*  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  --> */}

                                             <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                xmlnsa="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px"
                                                width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7"
                                                enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                                                <polygon className="triangle" id="XMLID_18_" fill="none" strokeWidth="7"
                                                   strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                                   points="
               73.5,62.5 148.5,105.8 73.5,149.1 "></polygon>
                                                <circle className="circle" id="XMLID_17_" fill="none" strokeWidth="7"
                                                   strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                                   cx="106.8" cy="106.8" r="103.3">
                                                </circle>
                                             </svg>
                                             <span>Watch Trailer</span>
                                          </a>
                                       </div>
                                    </div>
                                    <div className="col-xl-6">
                                       <div className="gen-tag-line"><span>Most Viewed</span></div>
                                       <div className="gen-movie-info">
                                          <h3>King of Skull</h3>
                                       </div>
                                       <div className="gen-movie-meta-holder">
                                          <ul className="gen-meta-after-title">
                                             <li className="gen-sen-rating">
                                                <span>
                                                   12A</span>
                                             </li>
                                             <li> <img src="images/asset-2.png" alt="rating" />
                                                <span>
                                                   0 </span>
                                             </li>
                                          </ul>
                                          <p>Streamlab is a long established fact that a reader will be distracted
                                          by the readable content of a page The point of using Lorem Streamlab.
                                       </p>
                                          <div className="gen-meta-info">
                                             <ul className="gen-meta-after-excerpt">
                                                <li>
                                                   <strong>Cast :</strong>
                                                Anna Romanson,Robert Romanson
                                             </li>
                                                <li>
                                                   <strong>Genre :</strong>
                                                   <span>
                                                      <a href="action.html">
                                                         Action, </a>
                                                   </span>
                                                   <span>
                                                      <a href="animation.html">
                                                         Annimation, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         Family </a>
                                                   </span>
                                                </li>
                                                <li>
                                                   <strong>Tag :</strong>
                                                   <span>
                                                      <a href="/">
                                                         4K Ultra, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         Brother, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         Dubbing, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         Premieres </a>
                                                   </span>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                       <div className="gen-movie-action">
                                          <div className="gen-btn-container">
                                             <a href="single-movie.html" className="gen-button .gen-button-dark">
                                                <i aria-hidden="true" className="fas fa-play"></i> <span className="text">Play
                                                Now</span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="item" style={{ background: "url('images/background/asset-3.jpeg')" }}>
                           <div className="gen-movie-contain-style-2 h-100">
                              <div className="container h-100">
                                 <div className="row flex-row-reverse align-items-center h-100">
                                    <div className="col-xl-6">
                                       <div className="gen-front-image ">
                                          <img src="images/background/asset-3.jpeg" alt="owl-carousel-banner" />
                                          <a href="https://www.youtube.com/watch?v=a3ICNMQW7Ok" className="playBut popup-youtube popup-vimeo popup-gmaps">
                                             {/*  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  --> */}

                                             <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                xmlnsa="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px"
                                                width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7"
                                                enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                                                <polygon className="triangle" id="XMLID_18_" fill="none" strokeWidth="7"
                                                   strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                                   points="
               73.5,62.5 148.5,105.8 73.5,149.1 "></polygon>
                                                <circle className="circle" id="XMLID_17_" fill="none" strokeWidth="7"
                                                   strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                                   cx="106.8" cy="106.8" r="103.3">
                                                </circle>
                                             </svg>
                                             <span>Watch Trailer</span>
                                          </a>
                                       </div>
                                    </div>
                                    <div className="col-xl-6">
                                       <div className="gen-tag-line"><span>Best Of 2021</span></div>
                                       <div className="gen-movie-info">
                                          <h3>The Express</h3>
                                       </div>
                                       <div className="gen-movie-meta-holder">
                                          <ul className="gen-meta-after-title">
                                             <li className="gen-sen-rating">
                                                <span>
                                                   PG-14</span>
                                             </li>
                                             <li> <img src="images/asset-2.png" alt="rating" />
                                                <span>
                                                   8.5 </span>
                                             </li>
                                          </ul>
                                          <p>Streamlab is a long established fact that a reader will be distracted
                                          by the readable content of a page when Streamlab at its layout
                                          Streamlab.
                                       </p>
                                          <div className="gen-meta-info">
                                             <ul className="gen-meta-after-excerpt">
                                                <li>
                                                   <strong>Cast :</strong>
                                                Robert Romanson,Anne Good
                                             </li>
                                                <li>
                                                   <strong>Genre :</strong>
                                                   <span>
                                                      <a href="action.html">
                                                         Action, </a>
                                                   </span>
                                                   <span>
                                                      <a href="adventure.html">
                                                         Adventure, </a>
                                                   </span>
                                                   <span>
                                                      <a href="biography.html">
                                                         Biography </a>
                                                   </span>
                                                </li>
                                                <li>
                                                   <strong>Tag :</strong>
                                                   <span>
                                                      <a href="/">
                                                         4K Ultra, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         King, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         Premieres, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         viking </a>
                                                   </span>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                       <div className="gen-movie-action">
                                          <div className="gen-btn-container">
                                             <a href="single-movie.html" className="gen-button gen-button-dark">
                                                <i aria-hidden="true" className="fas fa-play"></i> <span className="text">Play
                                                Now</span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="item" style={{ background: "url('images/background/asset-3.jpeg')" }}>
                           <div className="gen-movie-contain-style-2 h-100">
                              <div className="container h-100">
                                 <div className="row flex-row-reverse align-items-center h-100">
                                    <div className="col-xl-6">
                                       <div className="gen-front-image ">
                                          <img src="images/background/asset-4.jpeg" alt="owl-carousel-banner" />
                                          <a href="https://www.youtube.com/watch?v=a3ICNMQW7Ok" className="playBut popup-youtube popup-vimeo popup-gmaps">
                                             {/*  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  --> */}

                                             <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                xmlnsa="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px"
                                                width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7"
                                                enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                                                <polygon className="triangle" id="XMLID_18_" fill="none" strokeWidth="7"
                                                   strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                                   points="
               73.5,62.5 148.5,105.8 73.5,149.1 "></polygon>
                                                <circle className="circle" id="XMLID_17_" fill="none" strokeWidth="7"
                                                   strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                                   cx="106.8" cy="106.8" r="103.3">
                                                </circle>
                                             </svg>
                                             <span>Watch Trailer</span>
                                          </a>
                                       </div>
                                    </div>
                                    <div className="col-xl-6">
                                       <div className="gen-tag-line"><span>High Rated</span></div>
                                       <div className="gen-movie-info">
                                          <h3>thieve the bank</h3>
                                       </div>
                                       <div className="gen-movie-meta-holder">
                                          <ul className="gen-meta-after-title">
                                             <li className="gen-sen-rating">
                                                <span>
                                                   TV-MA</span>
                                             </li>
                                             <li> <img src="images/asset-2.png" alt="rating" />
                                                <span>
                                                   9.5 </span>
                                             </li>
                                          </ul>
                                          <p>Streamlab is a long established fact that a reader will be distracted
                                          by the readable content of a page when Streamlab at its layout
                                          Streamlab.
                                       </p>
                                          <div className="gen-meta-info">
                                             <ul className="gen-meta-after-excerpt">
                                                <li>
                                                   <strong>Cast :</strong>
                                                Jennifer Lonez,Mars Shelley
                                             </li>
                                                <li>
                                                   <strong>Genre :</strong>
                                                   <span>
                                                      <a href="action.html">
                                                         Action, </a>
                                                   </span>
                                                   <span>
                                                      <a href="action.html">
                                                         Mystery </a>
                                                   </span>
                                                </li>
                                                <li>
                                                   <strong>Tag :</strong>
                                                   <span>
                                                      <a href="/">
                                                         Brother, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         Hero, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         Premieres, </a>
                                                   </span>
                                                   <span>
                                                      <a href="/">
                                                         viking </a>
                                                   </span>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                       <div className="gen-movie-action">
                                          <div className="gen-btn-container">
                                             <a href="single-movie.html" className="gen-button gen-button-dark">
                                                <i aria-hidden="true" className="fas fa-play"></i> <span className="text">Play
                                                Now</span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}
export default Banner;