import React from 'react';
import Loader from '../../Loader';
import Header from '../../Header';
import Breadcrumb from '../../Breadcrumb';
import Movies from './Movies';

import Footer from '../../Footer';
import Scrolltop from '../../Scrolltop';
const Home = () => {
    return (
        <>
            <Loader />
            <Header />
            <Breadcrumb
                titles="Videos"
                title="Video"
            />
            <Movies />

            <Footer />
            <Scrolltop />
        </>
    );
}
export default Home;