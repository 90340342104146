import React from 'react';
import Moviecard from './Moviecard';
const Movies = () => {
    return (
        <section className="gen-section-padding-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="gen-style-1">
                            <div className="row">
                           
                            <Moviecard
                                    img="images/background/asset-14.jpeg"
                                    title="Ghost of Sky"
                                    seo="1 Season"
                                    cat="Action"
                                />
                                <Moviecard
                                    img="images/background/asset-15.jpeg"
                                    title="Love In 21st"
                                    seo="2 Season"
                                    cat="Action"
                                />
                                <Moviecard
                                    img="images/background/asset-16.jpeg"
                                    title="Family Love"
                                    seo="0 Season"
                                    cat="Action"
                                />
                                <Moviecard
                                    img="images/background/asset-17.jpeg"
                                    title="Dance nation Dance"
                                    seo="0 Season"
                                    cat="Comady"
                                />
                                <Moviecard
                                    img="images/background/asset-18.jpeg"
                                    title="Vacation Life"
                                    seo="1 Season"
                                    cat="Adventure"
                                />
                                <Moviecard
                                    img="images/background/asset-19.jpeg"
                                    title="Dream Of Dargons"
                                    seo="1 Season"
                                    cat="Drama"
                                />

                                <Moviecard
                                    img="images/background/asset-20.jpeg"
                                    title="Command In Your Hand"
                                    seo="1 Season"
                                    cat="Comedy"
                                />

                                <Moviecard
                                    img="images/background/asset-21.jpeg"
                                    title="Stories Of the Dark"
                                    seo="1 Season"
                                    cat="Biography"
                                />

                                <Moviecard
                                    img="images/background/asset-13.jpeg"
                                    title="Friend Of Jin"
                                    seo="1 Season"
                                    cat="Drama"
                                />
                                <Moviecard
                                    img="images/background/asset-22.jpeg"
                                    title="3 Hacker:TBG"
                                    seo="1 Season"
                                    cat="Drama"
                                />
                               <Moviecard
                                    img="images/background/asset-15.jpeg"
                                    title="Love In 21st"
                                    seo="2 Season"
                                    cat="Action"
                                />
                                 <Moviecard
                                    img="images/background/asset-77.jpg"
                                    title="Play For Life"
                                    seo="1 Season"
                                    cat="Action"
                                />
                                <Moviecard
                                    img="images/background/asset-62.jpg"
                                    title="Kimu-The pet elephant"
                                    seo="0 Season"
                                    cat="Action"
                                />


                                <Moviecard
                                    img="images/background/asset-37.jpeg"
                                    title="Against Beast"
                                    seo="2 Season"
                                    cat="Drama"
                                />
                                 <Moviecard
                                    img="images/background/asset-19.jpeg"
                                    title="Dream Of Dargons"
                                    seo="1 Season"
                                    cat="Adventure"
                                />
                                <Moviecard
                                    img="images/background/asset-77.jpg"
                                    title="Play For Life"
                                    seo="1 Season"
                                    cat="Action"
                                />
                                <Moviecard
                                    img="images/background/asset-35.jpg"
                                    title="War With Robots"
                                    seo="0 Season"
                                    cat="Action"
                                />
                                <Moviecard
                                    img="images/background/asset-68.jpg"
                                    title="Story Of Mahauki Shark"
                                    seo="0 Season"
                                    cat="Action"
                                />
                           
                           
                           
                           
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                    <div className="gen-pagination">
                        <nav aria-label="Page navigation">
                            <ul className="page-numbers">
                                <li><span aria-current="page" className="page-numbers current">1</span></li>
                                <li><a className="page-numbers" href="/">2</a></li>
                                <li><a className="next page-numbers" href="/">Next page</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}
export default Movies;