import React from 'react';
import Loader from '../../Loader';
import Header from '../../Header';
import Breadcrumb from '../../Breadcrumb';
import Blogpageleft from './Blogleft';
import Footer from '../../Footer';
import Scrolltop from '../../Scrolltop';
const Blogindexleft = () => {
    return(
        <>
       <Loader />
       <Header/>
       <Breadcrumb  titles="blog Left sidebar"
       title="blog Left sidebar" />
       <Blogpageleft />
       <Footer />
       <Scrolltop/>
       </>
    );
}
export default Blogindexleft;