import React from 'react';

const Scratch = (prop) => {
    return(
      
      <div className="slider-nav-contain">
      <div className="gen-nav-img">
          <img src={prop.img} alt="steamlab-source"/>
      </div>
      <div className="movie-info">
          <h3>{prop.title}</h3>
          <div className="gen-movie-meta-holder">
              <ul>
                  <li></li>
                  <li></li>
              </ul>
          </div>
      </div>
  </div>
    );
}
export default Scratch;
